@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
    --primary: rgba(110, 127, 214, 1);
    --textcolor: rgba(110, 127, 214, 1);
}



* {
    padding: 0;
    margin: 0;
    outline: none;
    font-family: "Inter", sans-serif;
}

.trip_list_btn ul {
    padding: 0px;
    margin: 25px 0px;
}

.trip_list_btn ul li {
    list-style-type: none;
    display: inline;
    font-size: 14px;
    font-weight: 600;
    margin: 0px 20px 0px 0px;
    padding: 8px;
    color: #191919CC;
    cursor: pointer;
}

.no-data_dummy-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.4px;
    color: #acacac;
}

.text_delete_color {
    color: red !important;
}

.trippage_inner_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.trippage_main_wrapper {
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 15px 15px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
}

.tripe_left_bar {
    display: flex;
}

.trips_picture_new {
    margin-right: 10px;
}

.trips_picture_new img {
    border-radius: 5px;
    height: 70px;
    width: 70px;
    margin: 0px 0px;
}

.value_profile_date {
    font-size: 12px;
    color: #606060;
    font-weight: 400;
}

.booking_id {
    font-size: 13px;
    font-weight: 500;
    color: var(--textcolor) !important;
}

.booked_status_inner_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 5px 0px 0px;
}

.booked_text_title {
    font-size: 12px;
    font-weight: 500;
    color: #606060;
}

.boked_status {
    font-size: 12px;
    font-weight: 500;
    color: #000;
}

.booked_date {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-left: 5px;
}

.completed_text {
    font-size: 12px;
    font-weight: 600;
    color: #15B182;
    margin-left: 5px;
}

/* Add this to your CSS file or inside a <style> tag */
@media print {
    body * {
        visibility: hidden;
    }

    .booking_confrim_pages,
    .booking_confrim_pages * {
        visibility: visible;
    }

    .booking_confrim_pages {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.completed_failed {
    font-size: 12px;
    font-weight: 600;
    color: #EF4547 !important;
    margin-left: 5px;
}

.checkout_error_text {
    text-transform: capitalize;
    line-height: 21px;
    font-weight: 700;
    color: #f7172d;
    margin: 0px;
    text-shadow: 1px 1px 2px #b2b2b270;
}

.checkout_error_wrapper_box {
    text-align: center;
    align-self: center;
    align-items: center;
    background: #e8e7e7;
    border-radius: 5px;
    padding: 15px 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.rating_star_inner_wrapper {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.rating_box {
    width: 70px;
    text-align: center;
}

.rating_title {
    font-size: 11px;
    font-weight: 500;
    color: #606060;

}

.rating {
    display: flex;
}

.rating>.rating_box>input {
    display: none
}

.rating>.rating_box>label {
    position: relative;
    width: 1em;
    font-size: 33px;
    font-weight: 300;
    color: #FFD600;
    cursor: pointer;
    height: 0px;
}

.rating>.rating_box>label::before {
    content: "\2605";
    position: absolute;
    opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
    opacity: 1 !important
}

.rating>input:checked~label:before {
    opacity: 1
}

.rating:hover>input:checked~label:before {
    opacity: 0.4
}


.addfeedback_title {
    font-size: 13px;
    font-weight: 500;
    color: var(--textcolor) !important;
}

.images_box {
    height: 200px;
}

.stycky_header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
}

.herosection {
    font-family: sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.background_overlay {
    height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.property_icon_heart {
    position: absolute;
    right: 10px;
    top: 10px;
}










/* .bar {
    width: 1000px;
    background: white;
    height: 62px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 0.6rem;
    box-shadow: rgb(0 0 0 / 13%) 0px 3px 8px;
} */

.bar gest_search {
    border-radius: inherit;
    padding: 0.8rem 1.5rem;
    transition: background 250ms ease;
}

.serach_input[type="text"] {
    background: none;
    border: none;
    padding: 0px 0px;
    font-size: 13px;
    font-weight: 500;
}

.serach_input[type="text"]:focus {
    outline: none;
}

.serach_input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
}

.guests {
    position: relative;
}

.guests span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: #FF385C;
    color: white;
    font-size: 0.8rem;
    padding: 0.7rem;
    border-radius: 50%;
}

.bar>div {
    position: relative;
}

.gest_search {
    padding: 10px 0px;
}

.searchmain_title {
    font-size: 12px;
    font-weight: 500;
    color: #606060;
    letter-spacing: 0.3px;
}

.rental_project_category {
    background: #F0F0F0;
}

.rental_project_category ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
}

/* .rental_project_category ul li {
    border-right: 1px solid #ece8e8;
    list-style: none;
} */

/******search box DATE HOME SCREEN**/
.rs-picker-popup.rs-picker-popup-daterange {
    padding: 0;
    margin-left: -45px;
}

.details_page_datepicker>.rs-picker-daterange-panel {
    background-color: red !important;
}

.rs-calendar-table-header-cell-content {
    display: inline-block;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 11px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
}

.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
    content: '';
    display: block;
    width: 100%;
    margin-top: 3px;
    height: 35px !important;
    position: absolute;
    z-index: 0;
    top: 0;
}

.rs-calendar-table-cell-content {
    display: inline-block;
    font-size: 12px !important;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
    font-weight: 500;
}

.rs-picker-popup .rs-calendar:first-child {
    border-right: none;
    border-right: none !important;
}

.search_destination>.css-1nmdiq5-menu {
    top: 100%;
    position: absolute;
    width: 300px !important;
    left: -55px;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 12px !important;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 12px !important;
    box-sizing: border-box;
}

.search_destination>.css-1nmdiq5-menu>.css-1n6sfyn-MenuList>div {
    padding: 8px 10px;
    border-bottom: 1px solid #f1f1f170;
}

.css-1n6sfyn-MenuList>div>img {
    margin-right: 8px;
    width: 30px;
}

.css-1n6sfyn-MenuList>div:hover {
    background-color: #ededed;
    cursor: pointer;
    border-radius: 8px;
}

.css-1dimb5e-singleValue {
    /* margin-left: -10px !important; */
}

.rs-picker-daterange-calendar-group {
    height: auto !important;
    min-width: 492px;
}

.rs-picker-popup {
    margin-top: 13px;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
    border-radius: 50%;
    line-height: 21px;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: var(--primary) !important;

}

.rs-calendar-month-dropdown {
    border-top: none;
    border-top: none;
}

.rs-btn-xs {
    font-size: 12px;
    font-weight: 600 !important;
}

.rs-btn-subtle {
    color: var(--textcolor) !important;
}

.rs-calendar-table-cell-content {
    margin: 5px;
}

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
    display: table-cell;
    width: 0%;
    padding: 0px 0;
}



.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* Ensure the loader is on top of other content */
    background-color: rgba(0, 0, 0, 0.48);
    /* Semi-transparent white background */
}


/* HTML: <div class="loader"></div> */
.loader_new {
    width: 40px;
    aspect-ratio: 1;
    --c: linear-gradient(#000 0 0);
    --r1: radial-gradient(farthest-side at bottom, #000 93%, #0000);
    --r2: radial-gradient(farthest-side at top, #000 93%, #0000);
    background:
        var(--c), var(--r1), var(--r2),
        var(--c), var(--r1), var(--r2),
        var(--c), var(--r1), var(--r2);
    background-repeat: no-repeat;
    animation: l1 1s infinite alternate;
    position: absolute;
    left: 50%;
    top: 50%;
}

@keyframes l1 {

    0%,
    10% {
        background-size: 8px 0, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%, 50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px), 100% calc(50% + 2px);
    }

    90%,
    100% {
        background-size: 8px 100%, 8px 4px, 8px 4px;
        background-position: 0 50%, 0 -2px, 0 calc(100% + 2px), 50% 50%, 50% -2px, 50% calc(100% + 2px), 100% 50%, 100% -2px, 100% calc(100% + 2px);
    }
}

/******END DATE HOME SCREEN**/
.rental_project_category li a {
    padding: 13px 9px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #343434;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    cursor: pointer;
    text-align: center;
    font-family: 'Inter';
}

.active_category {
    color: var(--textcolor) !important;
    border-bottom: 2px solid var(--textcolor) !important;
}

.active_category1 {
    color: #0096DC !important;

}


.checkbox-label {
    display: block;
    margin-bottom: 10px;
    /* Adjust margin as needed */
}

.checkbox-label input {
    display: none;
    /* Hide the default checkbox */
}

.checkbox-custom {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 3px;
    vertical-align: middle;
    margin-right: 10px;
}

.checkbox-label span {
    vertical-align: middle;
}

.guests_search_btn {
    width: 13%;
}

.guests_search_btn button {
    background-color: var(--primary);
    border-radius: 0px 10px 10px 0px;
    font-size: 16px;
    border: 0px;
    outline: none;
    color: #fff;
    display: block;
    font-weight: 400;
    width: 100%;
    height: 100%;
}

.model_size {
    width: 80% !important;
    margin: 0px auto;
}

.user_dropdown_menu.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: #E7E7E7 !important;
}

.login_form_body {
    font-family: "Poppins", sans-serif;
    height: 100vh;
    color: #3a3e42 !important;
    background: #e8e8e8;
}

.rentel_login_header h1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
    color: #222D57;
}

.AppForm .AppFormLeft input:focus {
    border-color: #3D425A;
}

.AppForm .AppFormLeft input::placeholder {
    font-size: 14px;
}

.AppForm .AppFormLeft .input_icone {
    position: absolute;
    /* left: 2px; */
    top: 20px;
    padding: 5px;
    margin-left: 7px;
    border-radius: 10px;
    font-size: 17px;
    color: #222D57B2;
    margin-top: 10px;
}

.input_icone img {
    width: 14px;
}

.AppForm .AppFormLeft .button {

    border-radius: 5px;
    width: 100%;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    padding: 8px 0px;
}

.AppForm .AppFormLeft p span {
    color: #006AA0;
}

.react-international-phone-input-container {
    display: flex;
    width: 100%;
}

/* .AppFormRight {
    height: 495px;
    background-size: cover;
    background-position: center;
    background-image: url(bulding.png);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
} */

.rental_property_login_form_wrapper {
    background-color: #fff;
    border-radius: 15px;
    /*width: 450px;*/
    margin: 0px auto;
    padding: 30px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;*/
}

.rental_login_fm {
    border-radius: 5px;
    display: block;
    margin-right: 33px;
    width: 100%;
    padding: 9px 35.75px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #3D425A;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.logiform_lable {
    font-size: 14px;
    font-weight: 500;
    color: rgba(34, 45, 87, 1);
    margin-bottom: 5px;
}

.AppForm .AppFormLeft .forgot_password_text a {
    float: right;
    color: rgba(34, 45, 87, 1);
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
}

.rentel_login_logo {
    text-align: center;
}

.rentel_login_logo img {
    width: 45px;
    padding-bottom: 15px;
}

.icon-wrapper {
    margin: 3px;
}

.icon-wrapper img {
    width: 45px;
    cursor: pointer;
}

.social_wrapper_login {
    display: flex;
    justify-content: center;
}

.bottom_dont_account {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: rgba(34, 45, 87, 1);
    padding: 10px 0px;
}

.sep-3 {
    border: none;
    height: 1px;
    background-image: linear-gradient(to right, #f0f0f0, #8f8f8f, #f0f0f0);
    opacity: 1;
    text-align: center;
}

.sep-3::after {
    content: 'OR';
    display: inline-block;
    position: absolute;
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: 50% 50%;
    border-radius: 100px;
    background-color: #fff;
    margin: 0px auto;
    text-align: center;
    height: 35px;
    width: 35px;
    line-height: 33px;
    font-weight: 600;
    font-size: 14px;
    color: #505050;
}

.line_border_spece {
    padding: 9px 0px;
}

.bottom_dont_account a {
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
}

.signupform_scroll {
    overflow-y: scroll;
    height: 225px;
}

.signupform_scroll::-webkit-scrollbar {
    -webkit-appearance: none;


}

.signupform_scroll::-webkit-scrollbar:vertical {
    width: 5px;
}


.signupform_scroll::-webkit-scrollbar-thumb {
    background: #c9cfdb;
    border-radius: 50px;

}

.signupform_scroll::-webkit-scrollbar-track {
    background-color: #ffffff;
    margin-top: 27px;

}

/********header menu*********/
.header_section {
    /* border-bottom: 1px solid #f4f0f0; */
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    /* position: fixed; */
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9999;
}

.gest_search_adult {
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
}

.input_box {
    margin-left: 10px;
    width: 90%;
}

.search_destination>.css-13cymwt-control {
    min-height: 0px;
    cursor: pointer;
    border-width: 0px;
}

.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}

.search_destination>.css-13cymwt-control>.css-1fdsijx-ValueContainer {
    padding: 0px 0px;
    box-sizing: border-box;
}

.search_destination>.css-t3ipsp-control {
    min-height: 0px !important;
    cursor: pointer;
    border-width: 0px !important;
    box-shadow: none !important;
    background: none !important;
}

.css-tr4s17-option {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #00b7d9 !important;
    color: hsl(0, 0%, 100%);
    padding: 8px 12px;
    box-sizing: border-box;
}

.css-1fdsijx-ValueContainer {
    padding: 0px 0px !important;
}

.search_destination>.css-13cymwt-control>.css-1fdsijx-ValueContainer>.css-1dimb5e-singleValue {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: hsl(0, 0%, 20%);
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
    font-size: 13px!important;
    font-weight: 500;
}


.search_destination>.css-13cymwt-control>.css-1fdsijx-ValueContainer>.css-qbdosj-Input {
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}



.property_rentel_nav>.nav-item>a {
    font-size: 13px;
    font-weight: 600;
    color: #191919;
    /* margin-top: -41px; */
    /* background-color: red; */
    padding: 14px;
    margin: 0px 1px;
    width: 135px;
    text-align: center;
}

.property_rentel_logo img {
    width: 107px;
    margin-left: 10px;
}

.adult_alert_error {
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
    text-align: center;
    padding-top: 10px;
    letter-spacing: 0.2px;
    color: red;
}

/* .property_rentel_nav>.nav-item {
    padding: 0px 25px;
} */

.property_rentel_nav_right>.nav-item>a {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
    line-height: 25px;
}

.dropdown-toggle::after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    display: none !important;
}

.users_header_icon img {
    height: 27px;
}

.user_dropdown_menu li a {
    font-size: 13px;
    font-weight: 500;
    color: #8a8a8a;
    /*padding: 7px 15px;*/
}

.user_dropdown_menu {
    left: -60px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.rangeslider_filter {
    color: var(--textcolor) !important;
}

.rangeslider_filter>.MuiSlider-thumb>.MuiSlider-valueLabelOpen {
    background-color: var(--primary);
    font-size: 12px!important;
}

.property_main_wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
}

.card_wrapper_box {
    height: 300px;
    border-radius: 10px;
    margin: 13px 0px;
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.text_inner_wrapper_box {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.text_wrapper_box {
    padding: 13px 12px;
}

.property_title_name h4 {
    font-size: 16px;
    font-weight: 500;
    color: #191919;
    margin-bottom: 2px;
}

.property_title_subname {
    font-size: 12px;
    color: #606060;
    font-weight: 400;
}

.property_icon_heart i {
    font-size: 21px;
    color: #000000ab;
    cursor: pointer;
}

.signle-product-space {
    margin-left: 0px !important;
    width: 100% !important;
}

.property_icon_heart img {
    width: 22px;
    cursor: pointer;
}

.property_title_area {
    font-size: 12px;
    color: #494848;
    font-weight: 500;
}

.ruppee_text {
    font-size: 16px;
    font-weight: 600;
    color: var(--textcolor) !important;

}

.booknow_btn a {
    color: var(--textcolor);
    border: 1px solid var(--textcolor) !important;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    height: 30px;
    padding: 6px 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    line-height: 16px;

}

.booknow_btn a:hover {
    background-color: var(--primary);
    color: #fff;
    border: none;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.card_btn_inner_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.cardbtn_wrpper {
    padding: 5px 0px 0px;
}

.viewdecoration_title {
    font-size: 20px;
    font-weight: 600;
    padding-top: 10px;
    color: #191919;

}

.viewdecoration_wrapper {
    padding: 15px 0px;
}

.viewdecoration_inner_wrapper {
    display: flex;
    box-shadow: rgb(99 99 99 / 7%) 0px 2px 8px 0px;
    border-radius: 10px;
    height: 329px;
}

.decoration_left {
    background: #FEF5EC;
    padding: 40px 30px;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #FEF5EC;
    width: 35%;

}

.viewdecoration_para {
    font-size: 18px;
    color: #A5A5A5;
    font-weight: 400;
    line-height: 23px;
    margin-top: 5px;
}

.decoration_right {
    width: 65%;
    border-radius: 0px 10px 10px 0px;
}

.viewdecoration_currency h4 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}

.logo_img_property img {
    width: 85px;
}

.viewdecoration_currency {
    padding: 13px 0px;
}

.appinfo_title h2 {
    font-size: 20px;
    font-weight: 600;
    /* padding-top: 30px; */
    color: #006AA0;
}

.appinfo_title_para h5 {
    font-size: 17px;
    color: #383737;
    padding-top: 0px;
    font-weight: 500;
}

.appsection_wrapper {
    padding: 25px 0px;
}

.appsection_inner_wrapper {
    padding: 35px 30px;
    background: #DFEDF7;
    border-radius: 10px;
}

.apps_images img {
    width: 60%;
}

.apps_images {
    text-align: center;
}

.downlode_apps_text {
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 15px;
}

.apps_downlode_inner_wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.donwlode_playsore {
    padding-left: 20px;
}

.donwlode_qrcode img {
    width: 115px;
}

/*****************footer******************/
.footer {
    background: #F1F1F1;
    padding-top: 40px;
    padding-bottom: 40px;
}

/*END FOOTER SOCIAL DESIGN*/

.footer_hedding_title h4 {
    color: #000;
    margin-top: 0;
    margin-bottom: 13px;
    font-weight: 500;
    font-size: 16px;
}

.footer_menu_list li {
    padding: 5px 0px;
}

.footer_menu_list li a {
    text-decoration: none;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    display: block;
}


.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer_apss_picture {
    margin: 5px 0px;
}

.footer_apss_picture img {
    width: 135px;
}

.copyright_wrapper {
    padding: 10px 0px;
    border-top: 1px solid #dedede96;
}

.conpyright_text {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-top: 9px;
}

.fotter_copyright_icone {
    text-align: right;
    display: flex;
    justify-content: end;
}

.card_box_riben {
    /* background: #22AFA6; */
    /* position: absolute; */
    right: 0px;
    top: 21px;
    font-size: 14px;
    color: rgba(25, 25, 25, 1);
    padding: 1px 5px;
    font-weight: 500;
    width: 20%;
    text-align: end;
    border-radius: 2px 0px 0px 2px;
}

/************details pages***************/
.property_detaols_main_wrapper {
    padding: 25px 0px;
}

.details_picture_banner img {
    height: 350px;
    border-radius: 5px;
    width: 100%;
    object-fit: cover;
}

.places {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
}

.img-short {
    width: 168px;
    height: 170px;
    float: left;
    margin: 3px 10px 10px 0px;
}

.img-short img {
    width: 165px;
    height: 162px;
    display: block;
}

.price {
    font-size: 21px;
    font-weight: 700;
    color: var(--textcolor) !important;
    /* padding: 0px 10px; */

}

.price i {
    font-size: 21px;
    padding-right: 5px;
    font-weight: 500;
    padding: 0px 5px;
}

.htro_price {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    color: #818080;
    /* padding: 0px 10px; */
    font-family: 'Inter';
}

.discount_price {
    font-size: 11px;
    font-weight: 500;
    color: var(--textcolor) !important;
    background: #E0F4FE;
    /* width: 17%; */
    padding: 2px 7px;
    border: 1px solid rgb(0 106 160 / 35%);
}

.details_pages_price {
    display: inline-flex;
    align-items: center;
    padding: 8px 0px;
    border-bottom: 1px solid #CBCBCB66;
    width: 100%;
}

.details_pages_sidebar {
    padding: 10px;
    border: 1px solid rgba(221, 221, 221, 1);
    border-radius: 8px;
    margin: 10px 0px;
    clear: both;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.notifaction_inner_wrapper {
    display: flex;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border: 1px solid #DDDDDD;
    margin-bottom: 10px;
    border-radius: 3px;
}

.noti_title h4 {
    font-size: 15px;
    font-weight: 600;
    color: #191919;
}

.message_icone {
    padding-right: 15px;
}

.message_icone img {
    width: 45px;
}

.noti_dis {
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

/**********details pages right side************/
.details_picture_tittle h4 {
    font-size: 20px;
    color: var(--textcolor) !important;
    font-weight: 600;
    text-transform: capitalize;
}

.details_picture_sub_tittle h5 {
    font-size: 16px;
    font-weight: 500;
    color: #606060;
}

.details_pages_rightside_inner {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    align-items: baseline;
}

.card_box_riben_dtais_page {
    background-color: var(--primary);
    font-size: 12px;
    color: #fff;
    padding: 1px 5px;
    font-weight: 500;
    text-align: center;
}

.r1lutz1s {
    font-size: 10px;
    font-weight: 600;
    color: #606060;
}

.features_main_title {
    font-size: 17px;
    font-weight: 600;
    color: #191919;
    padding-bottom: 10px;
}

.features_main_wrapper {
    padding: 20px 0px;
}

.iikjzje {
    display: flex;
    align-items: center;
}

.nametext {
    font-size: 14px;
    font-weight: 400;
    color: #606060;
}

.i4wvyiy {
    padding-right: 10px;
}

.features_lists_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.features_lists {
    width: 33%;
    padding: 10px 0px;
}

.i4wvyiy img {
    width: 23px;
}

.rating_details_wrapper {
    border: 1px solid #E1E1E1;
    padding: 20px;
    border-radius: 5px;
}

.card_box_riben_dtais_page_rightbox {
    background-color: var(--primary);
    font-size: 18px;
    color: #ffffff;
    padding: 10px;
    font-weight: 500;
    text-align: center;
    height: 95px;
    width: 95px;
    border-radius: 5px;
}

.reveiw_parformence {
    font-size: 13px;
    font-weight: 500;
}

.reveiw_number {
    font-size: 11px;
    font-weight: 500;
}

.progress-section {
    width: 100%;
    border-radius: 40px;
    background: #ffffff;
    padding: 1rem 2rem;
    margin: 0 auto;
    box-shadow: 0px 1px 10px 0px #0000001f;
}

.task-progress {
    width: 100% !important;
    margin-bottom: 6px;
    margin-top: 6px;
}

.task-progress p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #606060;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}



.progress {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
    height: 3px !important;
}

.progress::-webkit-progress-bar {
    background-color: #f0f0f0;
}

.progress::-webkit-progress-bar,
.progress::-webkit-progress-value {
    border-radius: 10px;
}

.progress::-moz-progress-bar {
    border-radius: 10px;
}

.progress1::-webkit-progress-value {
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%);
}

.review_user_name h4 {
    font-size: 15px;
    font-weight: 600;
    color: var(--textcolor) !important;
}

.review_user_shortdis h5 {
    font-size: 14px;
    font-weight: 500;
    color: #606060;
}

.review_weekly_tag h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #606060;
}

.review_log_dis h5 {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #606060;
    text-decoration: none;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.review_main_wrapper {
    padding: 10px 0px 30px;
}

.review_inner_wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E1E1E1;
    padding: 15px 0px;
}

.review_user_img {
    padding-right: 15px;
}

.allreview_btn {
    text-align: center;
    margin-top: 15px;
}

.recent_product_title h4 {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
}

.error-message {
    color: #cc0033;
    font-size: 13px;
    font-weight: 500;
    line-height: 7px;
    margin: 5px 0px 0px;
}

.form__input .hideshowpassword {
    position: absolute;
    right: 15px;
    font-size: 18px;
    top: 42px;
    opacity: 0.5;
    cursor: pointer;
}

.multiplueproduct {
    height: 400px;
    overflow-y: scroll;
}

.right_side_productlist {
    display: grid;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    -webkit-overflow-scrolling: touch;

    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
}


.right_side_productlist {
    object-fit: cover;
    width: 100%;
    height: auto;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.product_list_wrapper {
    /* background-color: red; */
    position: relative;
}


/******product list details***/
.details_page_adutldrop {
    width: 100%;
    /* position: absolute;
    inset: 0px auto auto 0px; */
}

.adult_input_box>.details_page_adutldrop {
    width: 100%;
}

.sidemultiplewrapper_inner {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    width: calc(100% + 4px);
    margin: calc(-2px);
    max-width: calc(100% + 4px);
}

.property_details_picture img {
    height: 85px;
    width: fit-content;
}


.places {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.img-short img {
    width: 225px;
    /* height: 170px; */
    display: block;
    border-radius: 5px;
    object-fit: cover;
}

.img-short {
    width: 48%;
    height: 163px;
    /* float: left; */
    margin: 0px 0px 0px 0px;
    /* margin: 1px auto;*/
}

.resverBtn {
    text-decoration-line: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 16px;
    text-align: center;
}

.rs-btn-primary {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: var(--primary) !important;
    border: none;
}

/********propertysearch*******/
.gest_search {
    display: flex;
    align-items: center;
}

.search_icone_herobox {
    padding-right: 10px;
    padding-left: 15px;
}

.search_icone_herobox1 {
    width: 10%;
}

.search_icone_herobox1 img {
    width: 21px;
}

.serach_input_date_time_piker {
    border: 0;
    font-size: 12px;
    font-weight: 500;
}

.css-hlgwow {
    padding: 2px 0px !important;
}

.review_model_main_wrapper>.modal-dialog>.modal-content {
    border-radius: 0px;
    width: 447px;
}

.review_model_header {
    background: #fff;
    border-radius: 0px !important;
    box-shadow: rgb(185 183 183 / 19%) 0px 3px 8px;
}

.review_model_header>.reviewtitle-model {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    padding: 11px;
}

/*******************************/
.review-form-wrapper {
    padding: 10px 30px;
}

.rating_review-list_title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(25, 25, 25, 1);
    padding-bottom: 10px;
}

.rating_review-list_sub_title {
    font-size: 12px;
    color: rgba(96, 96, 96, 1);
    font-weight: 400;
}

.rating,
.photo-upload,
.review-text {
    margin-bottom: 20px;
    margin-top: 20px;
}

.rating-popup {
    border-bottom: 1px solid rgba(228, 228, 228, 1);
    padding-bottom: 20px;
    padding-top: 10px;
}

.rating label {
    display: block;
    margin-bottom: 5px;
}

.stars {
    display: flex;
    flex-direction: row-reverse;
    /* Ensure the direction is set to row */
    justify-content: space-between;
}

.stars input {
    display: none;
}

.stars label {
    font-size: 35px;
    color: #ccc;
    cursor: pointer;
    width: 55px;
    text-align: center;
}

.stars input:checked~label,
.stars input:checked~label~label {
    color: #ffc107;
}

.photo-upload label,
.review-text label {
    display: block;
    margin-bottom: 5px;
}

.review_rating_name_title {
    font-size: 11px;
    color: rgba(96, 96, 96, 1);
    font-weight: 400;
    line-height: 18px;
}

.upload-area {
    border: 1.5px dashed var(--textcolor) !important;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    height: 95px;
    margin-top: 10px;
}

.upload-area label img {
    width: 24px;
    margin-top: 12px;
}

.upload-area input {
    display: none;
}

.review-text textarea {
    width: 100%;
    border: 1px solid rgba(185, 185, 185, 1);
    border-radius: 4px;
    padding: 10px;
    resize: none;
}

.upload-area-small {
    border: 1.5px dashed var(--textcolor) !important;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    height: 60px;
    width: 60px;
    margin-top: 0px;
}

.upload-area-small input {
    display: none;
}

.upload-area-small label,
.review-text label {
    display: block;
    margin-top: -5px;
}


.review-popup-btn {
    padding: 8px 30px !important;
    background-color: var(--primary) !important;
    border: none;
    border-radius: 4px;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.upload_images_list ul {
    display: flex;
    padding: 0px;
}

.upload_images_list ul li {
    list-style: none;
    padding: 0px 10px 0px 0px;
    position: relative;
}

.upload_images_list ul li .uploade-img-review {
    height: 60px;
    width: 60px;
}

.upload_images_list_clode_btn {
    position: absolute;
    top: -6px;
    right: 5px;
    background: #f1f1f1;
    height: 15px;
    width: 15px;
    border-radius: 100px;
    text-align: center;
    font-size: 10px;
    border: 1px solid var(--textcolor);
    line-height: 13px;
    color: var(--textcolor);
}

/*********************************/

.serach_input {
    background: none;
    border: none;
    padding: 0px 0px;
    font-size: 13px;
    font-weight: 500;
    color: #9c9c9c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

}


.serach_input_select_option_piker {
    width: 113px;
    background-color: #fff !important;
    border: 0;
    font-size: 12px;
    font-weight: 500;
}

select {
    width: 113px;
    background-color: #fff !important;
    border: 0;
    font-size: 12px;
    font-weight: 500;
}

option {
    background-color: #fff;
    /* Set background color of options */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.serach_input_gust {
    border: 0;
}

.text_in_one_line_property_name {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 3px;
}

.review_user_name {
    display: flex;
    /* justify-content: space-between; */
}

.error_input {
    color: #fb0000;
    font-size: 13px;
    font-weight: 300;
}

.check-in.gest_search {
    width: 33%;
}

.location.gest_search {
    width: 33%;
}

.guests.gest_search {
    width: 33%;
}

.rs-input-group.rs-input-group-inside {
    border: none !important;
}

.input-container {
    border: 1px solid #ccc;
    /* Border styles */
    display: inline-block;
    /* Ensures container doesn't expand to full width */
    padding: 5px;
    /* Adjust padding as needed */
}

.rs-date-range-input {
    width: 100%;
    /* Ensure input takes full width of container */
    box-sizing: border-box;
    /* Include padding and border in width calculation */
}





.bar {
    width: 1033px;
    background: white;
    height: 68px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    font-size: 0.6rem;
    box-shadow: rgb(0 0 0 / 13%) 0px 3px 8px;
    margin: 0px auto;
    font-size: 13px;
    font-weight: 500;
}

.bar gest_search {
    border-radius: inherit;
    padding: 0.8rem 1.5rem;
    transition: background 250ms ease;
}

.bar div:hover {
    /*background:hsl(0 0% 94%);*/
}

.location {
    width: 34%;
}

.check-in,
.check-out,
.guests {
    width: 22%;
}

.serach_input[type="text"] {
    background: none;
    border: none;
    padding: 5px 0px;
}

.serach_input[type="text"]:focus {
    outline: none;
}

.serach_input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #202020;
}

.guests {
    position: relative;
}

.guests span {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: #FF385C;
    color: white;
    font-size: 0.8rem;
    padding: 0.7rem;
    border-radius: 50%;
}

.bar>div {
    position: relative;
}


.bar>.gest_search::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.2px;
    height: 55%;
    background: #d9d9d991;
}

/* .bar>div:nth-of-type(1)::before {
    background: transparent;
} */

/* 
.bar>div:hover::before {
    background: transparent;
} */



.gest_search {
    padding: 10px 25px;
}

.searchmain_title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(25, 25, 25, 1);
    letter-spacing: 0.3px;
}

.guests_search_btn {
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%);
    border-radius: 0px 10px 10px 0px;
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    /* width: 15%; */
    line-height: 43px;
    border: 0px;
}

/* .rental_project_category {
    background: #f1f1f1;
    padding: 14px 0px 0px 0px; 
    border-bottom: 1px solid #dedede;
} */

.rental_project_category ul {
    margin: 0px;
    padding: 0px;
}

.rental_project_category ul li {
    display: grid;
    /* width: 100%; */
}

/* .rental_project_category li a {
    padding: 10px 9px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: #343434;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    cursor: pointer;
    text-align: center;
} */

.active1 {
    color: var(--textcolor) !important;
    border-bottom: 2px solid var(--textcolor) !important;
}

.confrim-deletion>.modal-dialog>.modal-content {
    border-radius: 13px;
    background-color: #fff;
    border: 1px solid #c9c6c6;
    width: 450px;
}

.confrim-deletion>.modal-dialog>.modal-content>.modal-header>.modal-title {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.4px;
}

.confrim-deletion {
    text-align: center;
}

.confrim_title_hedding {
    text-align: center;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding-bottom: 19px;
}

.body_text_title {
    font-size: 13px;
    font-weight: 600;
    color: #666464;
    text-transform: capitalize;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_confrim_deletion {
    border: 0px !important;
    padding: 7px 20px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.filter_inner_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.filter_number {
    font-size: 15px;
    color: #595959;
    font-weight: 400;
    /* padding: 0px 25px 0px 0px; */
    /* border-bottom: 1px dashed #c8bfbff0; */
}

.result_not_found {
    padding: 40px 0px;
}

.result_not_found img {
    width: 150px;
}

.filter_dropdown>.form-select {
    font-size: 14px;
    font-weight: 400;
    color: #595959;
    border: 1px solid #595959;
    border-radius: 3px;
}

.panel-heading a:after {
    /* font-family:'Glyphicons Halflings';*/
    /* content: "\2212"; */
    float: right;
    color: #191919;
    font-size: 20px;
}

.panel-heading a.collapsed:after {
    content: "\2b";
}

.panel-hea8ding a:after {
    content: "\2212";
    float: right;
    color: #191919;
    font-size: 20px;
}

.panel-heading a.collapsed:after {
    content: "\2b";
}

.filter_title_header>.panel-title>a {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
    text-decoration: none;
}

.filter_left_sidebar {
    margin-top: 20px;
    padding: 0px 25px 0px 0px;
}

.form-group {
    display: block;
    margin-bottom: 15px;
}

.form-group .filter_check {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group .filture_check_box {
    position: relative;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: #595959;
}

.form-group .filture_check_box:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--textcolor);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 9px;
    border-radius: 1px;
}

.form-group .filter_check:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 6px;
    height: 11px;
    border: solid var(--textcolor);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.price-input {
    width: 100%;
    display: flex;
}

.minmax_filed_title {
    font-size: 13px;
    color: #595959;
    font-weight: 500;
}

.input-min_box {
    border: 1px solid #595959;
    border-radius: 3px;
}

[slider] {
    position: relative;
    border-radius: 10px;
    padding: 40px 0px 30px;
    text-align: left;
}

[slider]>div {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 14px;
}

[slider]>div>[inverse-left] {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 10px;
    background-color: #CCC;
    margin: 0 0px;
}

[slider]>div>[inverse-right] {
    position: absolute;
    right: 0;
    height: 4px;
    border-radius: 10px;
    background-color: #CCC;
    margin: 0 0px;
}

[slider]>div>[range] {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 14px;
    background-color: #1ABC9C;
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%);
}

[slider]>div>[thumb] {
    position: absolute;
    top: -8px;
    z-index: 2;
    height: 19px;
    width: 19px;
    border: 4px solid #0097DC;
    text-align: left;
    margin-left: -11px;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    background-color: #FFF;
    border-radius: 50%;
    outline: none;
}

[slider]>input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 3;
    height: 14px;
    top: -2px;
    width: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

div[slider]>input[type=range]::-ms-track {
    -webkit-appearance: none;
    background: transparent;
    color: transparent;
}

div[slider]>input[type=range]::-moz-range-track {
    -moz-appearance: none;
    background: transparent;
    color: transparent;
}

div[slider]>input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
}

div[slider]>input[type=range]:focus {
    outline: none;
}

div[slider]>input[type=range]::-ms-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
}

div[slider]>input[type=range]::-moz-range-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
}

div[slider]>input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
    -webkit-appearance: none;
}

div[slider]>input[type=range]::-ms-fill-lower {
    background: transparent;
    border: 0 none;
}

div[slider]>input[type=range]::-ms-fill-upper {
    background: transparent;
    border: 0 none;
}

div[slider]>input[type=range]::-ms-tooltip {
    display: none;
}

[slider]>div>[sign] {
    opacity: 1;
    position: absolute;
    margin-left: -17px;
    top: -42px;
    z-index: 3;
    background-color: #1ABC9C;
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%);
    color: #fff;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
}

[slider]>div>[sign]:after {
    position: absolute;
    content: '';
    left: 0;
    border-radius: 16px;
    top: 19px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top-width: 16px;
    border-top-style: solid;
    border-top-color: #00c9d7;
}

[slider]>div>[sign]>span {
    font-size: 12px;
    font-weight: 700;
    line-height: 28px;
}

[slider]:hover>div>[sign] {
    opacity: 1;
}

.minuplus {
    font-size: 16px;
    margin-left: 0px;
    font-weight: 600;
    float: right;
    cursor: pointer;
}

/* .wapper-drop-down {
    padding: 3px 20px;
} */

.filtered-text {
    font-size: 11px;
    font-weight: 600;
}

.rs-input:focus {
    border: none !important;
    outline: #000;
}

.rs-picker-caret-icon.rs-icon {
    font-size: 23px;
}



.blinking-dot {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    animation: blink 1s infinite alternate;
    position: absolute;
    z-index: 9;
    right: 8px;
    bottom: 9px;
}

@keyframes blink {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}



.header {
    display: flex;
    align-items: center;
    justify-content: center;

}

.back-arrow {
    margin-right: 10px;
}

.profile-title {
    color: #007bff;
}

.userprofile {
    font-size: 30px;
    margin-bottom: 0px;
}

.card-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.value {
    flex: 1;
}

.noti-header {
    display: flex;
    justify-content: space-between;
}

.notistack-MuiContent {
    background-color: red !important;
    box-shadow: 14px 12px 0px 0px rgba(0, 0, 0, 0.2), 1px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;

}

.new-notification {
    background-color: #49dd4924;
    padding: 11px 4px;
}

.notification-count {
    position: absolute;
    z-index: 99;
    right: 12px;
    bottom: 7px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}


.form-column {
    width: 49%;
}

.checkout_main_wrapper {
    padding: 50px 0px;
    background: #FAFAFA;
}

.rentel_main_wrapperbox_checkout {
    width: 1000px;
    margin: 0px auto;
}

.yourtrip_wrapper_box {
    box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
    padding: 15px 20px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    width: 480px;
    margin-bottom: 30px;
    background: #fff;
}

.yourtrip_box {
    display: flex;
    align-items: center;
    padding: 15px 0px;
    border-bottom: 1px solid #D9D9D9;
}

.datetest_title {
    font-size: 14px;
    color: #191919;
    font-weight: 600;
}

.date_title_month {
    font-size: 12px;
    color: #606060;
    font-weight: 500;
    padding-top: 4px;
}

.date_box_left {
    width: 90%;
}

.date_box_rights {
    width: 10%;
    text-align: end;
}

.editpencile_icon img {
    width: 15px;
}

.flex_div_checkout {
    display: flex;
    align-items: baseline;
}

.classcheckout_dateicon {
    padding: 0px 7px;
}

.classcheckout_dateicon img {
    width: 16px;
}

.checkoutpage_title_box {
    font-size: 17px;
    font-weight: 600;
    color: #191919;
    padding-bottom: 10px;
}

.payment_input {
    margin: 12px 0px;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.payment_check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.payment_checkbox {
    font-size: 14px;
    font-weight: 500;
}

.cardname_wrapper {
    display: flex;
}

.cardname {
    margin: 0px 5px;
}

.pay_input_placeholder {
    font-size: 14px;
    color: #606060;
    font-weight: 400;
    font-family: Inter;
    display: block;
    width: 100%;
    padding: 8px 15px;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.cancelpolcey {
    font-size: 14px;
    font-weight: 500;
    color: #606060;
}

.payment_confrim_btn {
    border: 0px;
    padding: 8px 40px;
    border-radius: 5px;
    background-color: var(--primary);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter';
}

.confrim_inner_wrapper {
    display: flex;
    border-bottom: 1px solid #CBCBCB66;
    padding: 20px 15px;
    position: relative;
}

.reateprice_cehckout {
    padding: 10px 15px;
}

.rental_checkoutimg {
    width: 25%;
}

.rental_checkoutimg img {
    height: 85px;
    width: 100px;
    border-radius: 5px;
}

.checkout_pageribben {
    font-size: 11px;
    color: #fff;
    padding: 1px 5px;
    font-weight: 500;
    text-align: center;
    background-color: var(--primary);
    float: right;
    width: 50px;
    border-radius: 1px;
    position: absolute;
    right: 25px;
}

.rental_checkout_title {
    padding-left: 10px;
    width: 75%;
}

.checkoutporductname p {
    font-size: 15px;
    font-weight: 600;
    color: #191919;
    margin-bottom: 7px;
    line-height: 20px;
}

.rental_checkout_title p {
    /* font-size: 16px;
    font-weight: 500;
    color: #015B77;
    font-family: 'Inter';
    line-height: 19.36px;
    margin-bottom: 3px; */
}

.adoneservices_main_wrapper {
    display: flex;
    align-items: center;
    margin: 0px 0px;
    padding: 11px 0px;
    /* border-bottom: 1px solid #CBCBCB66; */
}

.adoneservices_text {
    width: 70%;
}

.adoneservices_button {
    width: 30%;
}

.button_adone_main {
    float: right;
    border: solid #006aa0ab;
    border-width: 1.3px;
    border-radius: 4px;
    overflow: auto;
    width: 115px;

}

.minmax_adonebtn {
    cursor: pointer;
    border: 0px;
    background-color: #fff;
    border-right: 1px solid var(--textcolor);
}

.btndesign {
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    cursor: pointer;
}

.filter_checkbox_btn {
    margin-bottom: 10px;
}

.adoneservices_producttitle {
    font-size: 14px;
    font-weight: 600;
    color: #191919;
}

.pricebox_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0px;
}

.priductname {
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

.pricedetails {
    font-size: 15px;
    font-weight: 600;
    color: #191919;
    padding-bottom: 10px;
}

.yourtrip_wrapper_box1 {
    box-shadow: rgb(99 99 99 / 11%) 0px 2px 8px 0px;
    border-radius: 8px;
    border: 1px solid #cbcbcb66;
    background: #fff;
    margin-bottom: 20px;
    width: 450px;
}

.totalprice_inner_checkout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-top: 1px solid #CBCBCB66;
}

.total_price {
    font-size: 16px;
    font-weight: 600;
    color: var(--textcolor) !important;
}

.checkout_pages_backtitle h3 {
    font-size: 15px;
    font-weight: 600;
    color: var(--textcolor) !important;
    font-family: 'Inter';
    padding-bottom: 10px;
}

.checkout_pages_backtitle img {
    width: 15px;
    margin: 0px 5px;
}

.rs-picker-toggle-wrapper {
    margin: -8px 1px -10px -15px !important;
}

.rs-input-group.rs-input-group-inside {
    width: 100%;

}

.gust-text {
    font-size: 14px;
    color: #282727b2;
    display: flex;
    width: 600px;
    margin: 2px 9px;
    font-size: 13px;
    font-weight: 500;
}


.social_icon {
    margin: 0px 5px;
    border-radius: 4px;
    height: 34px;
    width: 34px;
    background-color: var(--primary);
    text-align: center;
    line-height: 35px;

}

.social_icon a {
    color: #fff;
    display: block;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Added to horizontally center the header */
}

.back-arrow {
    margin-right: 10px;
}

.profile-title {
    color: #007BFF;
}

.userprofile {
    font-size: 30px;
    margin-bottom: 0;
}

.profile_wrapper {
    width: 900px;
    margin: 0px auto;
}

.profile_section_wrapper {
    border: 1px solid rgba(209, 209, 209, 1);
    border-radius: 4px;
    margin: 15px auto;
    padding: 30px 30px;
    /* box-shadow: rgb(99 99 99 / 16%) 0px 2px 8px 0px; */
    background: #fff;
}

.trips_picture img {
    border-radius: 5px;
    height: 70px;
    width: 70px;
    margin: 0px 18px;
}

.trip_arrow_icon {
    font-size: 15px;
    color: rgba(25, 25, 25, 1);
}

.trip_rating {
    font-size: 20x !important;
    padding: 8px 0px;
}

.tripwrapper_row {
    display: flex;
    align-items: center;
    padding: 12px 7px;
    text-decoration: none;
}

.tripwrapper_row:hover {
    text-decoration: none;
    /* Ensure underline is removed on hover */
}

.trip_left_bar {
    width: 15%;
}

.trip_center_bar {
    width: 70%;
}

.trip_right_bar {
    width: 15%;
}

.security_form_inner_wrapper {
    padding: 40px 10px;
}

.security_form {
    border-radius: 4px !important;
    padding: 9px 15px !important;
}

.btn_secourty {
    background-color: var(--primary) !important;
    border: 0px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 8px 25px !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 6px;
}

.btn_secourty:disabled {
    border: 0px;
    padding: 8px 30px;
    background: grey !important;
    color: #fff !important;
    border-radius: 5px;
    font-size: 14px;
    cursor: not-allowed !important;
}

.btn_secourty_cancel {
    border: 0px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    padding: 8px 25px !important;
    background-color: grey !important;
    color: #fff !important;
}

.deletebtn {}

.security_login_updatebtn {
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    text-decoration: underline;
}

.profile_section {
    padding: 20px 0px 20px;
    background: #f9f9f9;
  
}

.border-bottom_profile {
    margin: 0rem 0;
    border: 1px solid #bab6b685;
}

/* .card {
    background-color: #fff;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 70%;
} */

.card-row {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
}



.value {
    flex: 1;
}

.card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.666667%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.666667%;
}

.title_profile {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
    text-transform: capitalize;
}

.value_profile {
    font-size: 14px;
    color: #606060;
    font-weight: 400;
}

.profilebackbtn {
    font-size: 16px;
    color: var(--textcolor) !important;
    font-weight: 600;
    margin-left: 3px;
}

.notifaction_wrapper {
    padding: 15px 15px;
    margin-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    position: relative;
}

.backarrowbtn {
    font-weight: 600;
    color: var(--textcolor) !important;
}

.profileeditepencile i {
    font-size: 16px;
    cursor: pointer;
    color: #515151;
}

.profile_save_btn {
    background-color: var(--primary) !important;
    color: #fff !important;
    font-size: 14px !important;
    border: 0px !important;
    padding: 7px 30px !important;
    border-radius: 6px;
    margin-left: 15px;
    cursor: pointer;

}

.profile_save_btn:disabled {
    background-color: grey !important;
    color: #fff !important;
    font-size: 14px !important;
    border: 0px !important;
    padding: 7px 30px !important;
    border-radius: 6px;
    margin-left: 15px;
    cursor: not-allowed;

}


.profile_edit_form_input {
    display: flex;
    align-items: center;
}

.date_natifaction {
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--textcolor) !important;
}

.photo_grid-more {
    position: absolute;
    right: 16px;
    bottom: 16px;
    left: 0px;
    margin-left: 4px;
}

.photo_grid-more button {
    background: #161616c9;
    border: 1px solid #918e8e;
    color: #fff;
    padding: 0px 20px;
    line-height: 32px;
    /* display: flex; */
    /* align-items: center; */
    justify-content: center;
    /* font-weight: 500; */
    border-radius: 3px;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.45);
    /* border: 0px; */
    font-size: 12px;
    
}



.col-2 p {
    color: blue;
    /* Make the price blue */
    font-size: 14px;
    /* Adjust font size as needed */
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

.btnpl {
    border-right: 0px solid cyan !important;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 1px 7px;

}

.btn.disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}


/***************card design **************/
.images_box>.nav-link>img {
    border-radius: 10px 10px 0px 0px;
    width: 100% !important;
    height: 200px;
}

.afterlogin_dropdown>.dropdown-item {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
}

.before_login_header {
    display: flex;
    align-items: center;
}

.before_login_title {
    font-size: 13px;
    font-weight: 600;
    color: #191919;
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.afterlogin_dropdown,
.beforelogin_dropdown {
    width: 220px;
    box-shadow: rgb(0 0 0 / 21%) 0px 1px 4px;
    border: 0px !important;
}

.divider_border {
    border-top: 1px solid rgb(180 173 173 / 27%) !important;
}

/* .beforelogin_dropdown>.dropdown-item{
border-bottom: 1px solid #f1f1f1;
} */
.beforelogin_dropdown>.dropdown-item>a {
    font-size: 14px;
    text-decoration: none;
    color: #191919;
    font-weight: 500;
    display: block;
    padding: 5px 0px;
    font-family: 'Inter';
}

.beforelogin_dropdown>.loginoutbtn {
    font-size: 14px;
    text-decoration: none;
    color: #191919;
    font-weight: 500;
    display: block;
    padding: 5px 20px;
    font-family: 'Inter';
}

.details_page_price_wrapper {
    padding: 10px 3px;
}

.details_page_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.details_price_title_resverbox {
    font-size: 12px;
    font-weight: 500;
    color: #666565;
}

.details_price_resverbox {
    font-weight: 600;
    font-size: 14px;
}

.subtax_price {
    font-size: 10px;
    font-weight: 500;
}

/********************************************************************************************************/



.order-container {
    border-radius: 13px;
    padding: 20px 5px;
    /* box-shadow: 0 0 5px 5px #00000015; */
}


.order-data {
    border: 1px solid #00000050;
    border-radius: 10px;
    /* margin-bottom: 15px;
}

.date-picker {
    /* display: flex; */
    /* justify-content: space-between; */
}

.help_savebtn {
    border: 0px;
    padding: 8px 30px;
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}

.help_savebtn:disabled {
    border: 0px;
    padding: 8px 30px;
    background: grey;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    cursor: not-allowed;
}

.helpcontactnumber {
    padding: 14px 0px;
}

.helpcontactnumber p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(25, 25, 25, 1);
}

.date-input {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 5px 12px;
    color: #191919;
    font-size: 14px;
    font-weight: 400;
}

.date-input:last-of-type {
    border-left: 1px solid #00000050;
}

.guest-input {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-top: 1px solid #00000050;
    padding: 7px 12px;
    color: gray;
    font-size: 0.75rem;
}

.details_input {
    border: none;
    padding: 0 0;
    width: 100%;
    outline: none;
}

.labalename {
    font-weight: 400;
    font-size: 12px;
    color: #606060;
    text-transform: capitalize;
}


.btn-container {
    position: relative;
    width: 100%;
    height: 48px;
    padding: 0 0;
    margin: 0 0;
    cursor: pointer;
    border: none;
    display: grid;
    grid-template: repeat(10, 1fr) / repeat(10, 1fr);
}

.content_button_details .action-btn {
    background-color: var(--primary);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    height: 40px;
    padding: 6px 10px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    line-height: 25px;
    width: 100%;
    border: 0px;
    text-align: center;
    display: block;
    cursor: pointer;
}

.smenty_flx {
    display: flex;
    align-items: center;
}

.amenity_icoin img {
    width: 27px;
    margin-right: 7px;
}

.reason_cancellation_titletext_left>.font_textreason {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.font_textreason_reason_verfy {
    font-size: 13px;
    font-weight: 400;
    color: var(--textcolor) !important;
}

.reason_cancellation_innernwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    border-bottom: 1px dashed #e8e8e8;
    cursor: pointer;
}

.reason_cencellation_title {
    font-size: 16px;
    font-weight: 700;
    padding: 12px 0px;
}

.font_textreason i {
    font-size: 17px;
    padding-right: 9px;
    color: var(--textcolor) !important;
}

.help_savebtn_tellus {
    border: 0px;
    padding: 8px 30px;
    background-color: rgba(231, 67, 55, 1);
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
}

.confrim_cencel_main_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px;
}

.confrim_cencel_main_wrapper_box_wrapper {
    padding-top: 10px;
}

.totalamount_confrom_cencel_left {
    font-size: 12px;
    font-weight: 500;
    color: rgba(96, 96, 96, 1);
    text-transform: capitalize;
}

.totalamount_confrom_cencel_right {
    font-size: 12px;
    font-weight: 500;
    color: rgba(96, 96, 96, 1);
    text-transform: capitalize;
}

.refund_payemt_text p {
    color: rgba(96, 96, 96, 1);
    font-size: 14px;
    font-weight: 400;
    padding-top: 20px;
}

.react-international-phone-input-container .react-international-phone-input {

    width: 100%;
}

.react-international-phone-country-selector-button__button-content {
    margin-left: 15% !important;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    border-right: none;
}

/* Custom styles for the PhoneInput component */

/*******************Home dropdown********/
.adultdropdown_titlename {
    padding: 20px 0px 0px 10px;
    font-size: 16px;
    font-weight: 600;
    color: #191919;
}

.col1-4 {
    font-size: 14px;
    font-weight: 500;
    color: #191919;
}

.adult_dropdown_home {
    padding: 0px 0px 20px !important;
    border: 0px !important;
    border-radius: 12px !important;
    margin-top: 5px !important;
    box-shadow: rgb(50 50 93 / 14%) 0px 6px 12px -2px, rgb(0 0 0 / 26%) 0px 3px 7px -3px;
}

.number_adult_num_min_wrapper {
    display: flex;
    align-items: center;
}

.numbervaluespeace {
    text-align: center;
    color: #191919;
    height: 20px;
    width: 20px;
    border-radius: 100px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;

}

.wapper-drop-down {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
    padding: 10px 15px;
}

:nth-last-child(2n+0) {}

.wapper-drop-down:nth-last-child(-n+1) {
    border: 0px;
}

.number_button {
    color: #191919;
    height: 28px;
    width: 28px;
    border-radius: 100px;
    border: 1px solid #191919;
    line-height: 26px;
    font-size: 19px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    margin: 0px 5px;
}

.subtitle_ault_dropdown {
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #606060;
}

.rs-input-group-addon {
    display: none !important;
}

.rs-input {
    display: block;
    width: 100%;
    color: #575757;
    color: var(--rs-text-primary);
    background-color: #e6313100;
    background-image: none;
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    -webkit-transition: border-color ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s;
    border-radius: 0px;
    padding: 0px 0px !important;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 1.42857143;
}

.rs-input-group.rs-input-group-inside {
    margin-left: 15px;
}

.rs-input-group.rs-input-group-inside .rs-input {
    cursor: pointer;
    font-size: 12px !important;
    color: #575757 !important;
}

/**********home page area search*****/
.css-1mm796u-control {
    border-radius: 4px;
    border-style: solid;
    border-width: 0px !important;
    box-sizing: border-box;
    width: 203px;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.css-1u9des2-indicatorSeparator {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    width: 0px !important;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    box-sizing: border-box;
}

.css-14gyywz-MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    -webkit-transform: translateY(-100%) scale(1);
    -moz-transform: translateY(-100%) scale(1);
    -ms-transform: translateY(-100%) scale(1);
    transform: translateY(-100%) scale(1);
}

.css-14gyywz-MuiSlider-valueLabel {
    z-index: 1;
    white-space: nowrap;
    font-family: Inter;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: absolute;
    border-radius: 2px;
    color: #fff;
    padding: 5px 10px !important;
}

.shortbylable {
    font-size: 14px;
    color: #595959;
    font-weight: 400;
    margin-top: 4px;
}

.sort_select_input {}

.profile_page_left {
    width: 100%;
}

.avatar-upload_profile_rental {
    position: relative;
    max-width: 130px;
    margin: 20px auto 20px;
}

.avatar-upload_profile_rental .avatar-edit {
    position: absolute;
    right: 75px;
    z-index: 1;
    top: 10px;
}

.avatar-upload_profile_rental .avatar-edit input {
    display: none;
}

.avatar-upload_profile_rental .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 15px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 2px 1px 20px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 0px;
    left: 35px;
}

.avatar-upload_profile_rental .avatar-edit input+label i {
    /* content: "\f040"; */
    /* font-family: 'FontAwesome'; */
    color: var(--textcolor) !important;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    font-size: 15px;
}

.avatar-upload_profile_rental .avatar-preview {
    width: 130px;
    height: 130px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload_profile_rental .avatar-preview>div {

    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url(/6c5bc427-83a6-44bd-9b21-5445c2255375.webp); */
    background-position: center;
    background-color: var(--primary) !important;
}

.details_pages_dis p {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
}

.order_alert {
    font-size: 14px;
    font-weight: 500;
    color: red;
}

.mobile_btn_checkout {
    display: none;
}

.mobile_cenclelation {
    display: none;
}

.filter_react_all {
    border: 0px;
    background: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--textcolor);
    float: right;
}

.mobile_filter {
    display: none;
}

.privecy_polcy_wrapper {
    padding: 40px 0px;
}

.privecy_polcy_innerwrapper h6 {
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
}

.privecy_polcy_innerwrapper p {
    font-size: 14px;
    line-height: 25px;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
    outline: none !important;
    /* outline: 3px solid var(--rs-color-focus-ring); */
}

.rs-btn-primary {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 20px;
    padding: 0px;
    border-radius: 50% !important;
    height: 35px;
    width: 35px;
    background-color: var(--primary);
}

.rs-btn-link {
    color: #006AA0 !important;
    background-color: transparent;
    border: none;
    font-size: 12px !important;
    font-weight: 500;
    font-family: 'Inter';
}

.css-1xc3v61-indicatorContainer {
    display: none !important;
}

.css-1mm796u-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 0px !important;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0deg 0% 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 100% !important;
}

/*******header nav******/

.sidenav {
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    padding-top: 10px;
    transition: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: width 0.3s ease;
}



/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover,
.offcanvas a:focus {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 32px;
    margin-left: 0px;
    cursor: pointer;
}

.mobile_menu_text a {
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    /* background: red; */
    border-bottom: 1px solid #f1f1f1ad;
    display: block;
    color: #414141;
    padding: 10px 20px;
}

.mobile_menu_text a:hover {
    color: #006AA0;
}

.mobile_profile_textname {
    font-size: 14px;
    font-weight: 600;
    color: #191919;
    white-space: nowrap;
    width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    margin-left: 9px;
}

.mobile_profile_picture img {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    border: 2px solid #d5d5d5;
}

.mobileuserprofile_inner_wrapper {
    display: flex;
    align-items: center;
}

.user_profile {
    padding: 10px 20px;
}

.about_page_wrapper {
    padding: 20px 0px 30px;
}

.about_text_wrapper p {
    font-size: 15px;
}

.property-card-about_list ul {
    padding: 0px;
    margin-left: 15px;
}

.property-card-about_list ul li {
    font-size: 14px;
    padding: 5px 0px;
    list-style: circle;
    font-weight: 500;
}

.about_hedding_title {
    font-size: 22px;
    font-weight: 600;
    padding: 0px 0px 17px;
}

.reviewbtn {
    font-size: 16px;
    cursor: pointer;
}

.ratingtext {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.rewiewBtn {
    background-color: var(--primary) !important;
    width: 100%;
    padding: 9px !important;
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    margin-top: 5px;
}

.rating_star.css-1qqgbpl-MuiRating-root {
    position: relative;
    font-size: 18px;
    color: #faaf00;
    cursor: pointer;
    text-align: left;
    padding-bottom: 7px;
}

.review_model_city_country_title h5 {
    font-size: 13px;
    padding-left: 5px;
}

.review_model_hohelname_title h4 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 5px;
}

.review_rating_box {
    padding: 13px 0px;
}

.css-157pzgc-MuiAvatar-root {
    width: 50px !important;
    height: 50px !important;
}

/*********booking confrim css*****/
.yourtrip_wrapper_box_booking_confrim {
    width: 750px;
    margin: 0px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
}

.booking_confrim_pages {
    padding: 40px 0px;
    background: #fafafa;
}

.booking_confrim_title {
    border-bottom: 1px solid rgb(217 217 217 / 40%);
    padding: 10px 0px;
}

.booking_confrim_title h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--textcolor);
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
}

.booking_confrim_box_inner_wrapper {
    padding: 15px 0px 10px;
}

.bookign_id {
    color: rgba(25, 25, 25, 1);
    font-size: 15px;
    font-weight: 500;
}

.booking_date {
    font-size: 12px;
    color: rgba(96, 96, 96, 1);
    font-weight: 500;
}

.booking_text_confrim_idwrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 15px;
}

.booking_product_title h6 {
    color: rgba(1, 91, 119, 1);
    font-weight: 600;
    font-size: 16px;
}

.booking_product_dis h6 {
    font-weight: 400;
    font-size: 13px;
    color: rgba(96, 96, 96, 1);
}

.disrection_title h6 {
    font-weight: 600;
    font-size: 15px;
    color: rgba(25, 25, 25, 1);
}

.direction_wrapper {
    padding-top: 15px;
}

.booking_product_wrapper_confrim {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.booking_confrim_picture img {
    height: 100px;
    width: 100%;
    border-radius: 5px;
}

.booking_confrim_details h6 {
    color: rgba(96, 96, 96, 1);
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
}

.booking_confrim_details_title h6 {
    font-size: 13px;
    font-weight: 500;
    color: rgba(25, 25, 25, 1);
}

.booking_confrim_table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.confrim_booking_width {
    width: 33.33%;
}

.detail_wrapper_details {
    border-top: 1px solid rgb(217 217 217 / 40%);
    padding: 15px 0px 0px;
}


/*******trip booking details*****/
.tripbooking_id_wrapper {
    display: flex;
    justify-content: space-between;
}

.tripbookingid {
    color: rgba(25, 25, 25, 1);
    font-size: 14px;
    font-weight: 600;
}

.tripbookingid_datetime {
    color: rgba(96, 96, 96, 1);
    font-size: 11px;
    font-weight: 500;
}

.trips_details_pitcure img {
    height: 100px;
    width: 120px;
    border-radius: 5px;
}

.trip_details_flex_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.trips_details_product_name {
    font-size: 14px;
    font-weight: 600;
}

.trips_details_product_dis {
    color: rgba(96, 96, 96, 1);
    font-size: 12px;
    font-weight: 500;
}

.trips_details_booking_details_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
}

.booking_details_title_trips {
    font-size: 13px;
    color: rgba(96, 96, 96, 1);
    font-weight: 500;
}

.booking_details_sub_title_trips {
    color: rgba(25, 25, 25, 1);
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
}

.trips_details_booking_clumn {
    width: 15%;
}

.trip_page_details_payemnt_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}

.details_trip_left_price_title {
    color: rgba(25, 25, 25, 1);
    font-size: 14px;
    font-weight: 600;
    width: 70%;
}

.details_trip_right_price {
    color: rgba(96, 96, 96, 1);
    font-size: 14px;
    font-weight: 500;
    width: 30%;
    text-align: end;
}

.trips_booking_details_wrappar {
    padding: 13px 0px;
}

.trip_details_payemnt_mainwrapper {
    border-top: 1px solid rgba(217, 217, 217, 1);
    border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.trip-details_cancellation_title {
    font-size: 13px;
    color: rgba(25, 25, 25, 1);
    font-weight: 500;
}

.trip-details_cancellation_subtitle {
    font-size: 12px;
    color: rgba(96, 96, 96, 1);
}

.trip-details_cancellation_wrapper {
    padding: 5px 0px;
}

.cancellation_title_tripdetails {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: inherit;
}

.cancellation_main_wrapper_trip_details {
    padding: 20px 0px;
}

.cancel_trip_details_btn {
    border: 1px solid red;
    text-decoration: none;
    color: red;
    font-size: 13px;
    padding: 7px 30px;
}

.result_not_found {
    padding: 40px 0px;
}

.direction_title_wrapper {
    padding-top: 15px;
}

.view-breakup_text {
    font-size: 10px;
    font-weight: 500;
    color: var(--textcolor) !important;
}

.flex_letus_wrapper {
    display: flex;
    justify-content: space-between;
}

.tellus_more_text {
    color: var(--textcolor) !important;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
}

























.booking_confrim_footer_email p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(96, 96, 96, 1);
}

.booking_confrim_footer_email_text p {
    font-weight: 500;
    color: rgba(25, 25, 25, 1);
    font-size: 14px;
}

.print_btn {
    color: rgba(1, 91, 119, 1);
    border: 1px solid rgba(1, 91, 119, 1);
    padding: 6px 30px;
    display: block;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

.successfully_icon {
    width: 27px;
    margin-top: -5px;
    margin-right: 5px;
}

.bookedimg_watermark img {
    position: absolute;
    right: 0px;
    left: 0px;
    text-align: center;
    top: 145px;
    bottom: 0px;
    width: 25%;
    margin: 0px auto;
}

.mobile_booking {
    display: none;
}

.rental_product_rating {
    display: flex;
    padding: 15px 0px;
}

.rental_product_tilte_rating {
    text-align: start;
    margin-left: 15px;
}

.productname_rating {
    font-size: 15px;
    font-weight: 500;
}

.productname_rating_dis {
    font-size: 13px;
    font-weight: 400;
    color: rgba(96, 96, 96, 1);
}



/*--------- Pager2 ---------*/
.rating_pagination {}

.rating_pagination .pagination {
    justify-content: center;
}

.rating_pagination .pagination li {
    margin: 2px;
}

.rating_pagination .pagination li a {
    height: 36px;
    width: 36px;
    color: #006AA0;
    font-size: 14px;
    text-align: center;
    line-height: 23px;
    font-weight: 500;
}

.pagination_active {
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%) !important;
    color: #fff !important;
}

.property_notavible_alert_wrapper {
    margin-top: 20px;
}

.property_notavible_alert {
    font-size: 12px;
    font-weight: 500;
    color: #e8e8e8;
    line-height: 15px;
    background: #dc3545;
    padding: 14px 14px;
    border-radius: 10px;
    font-family: 'Inter';
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    text-align: center;
}

/*********property details******/
.property_details_main_wrapper {
    background-color: #e8e8e8;
    padding: 40px 0px;
}

.property_details_form_wrapper {
    width: 780px;
    margin: 0px auto;
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.property_details_label {
    color: rgba(34, 45, 87, 1);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px !important;
}

.form_title_proerty_details {
    font-size: 16px;
    color: rgba(1, 91, 119, 1);
    font-weight: 600;
    padding: 0px 0px 15px;
}

.border_form_details {
    border: 1px solid rgba(177, 177, 177, 1) !important;
}

.submit_btn_property_details {
    border: 0px;
    background: linear-gradient(90deg, #00C9D7 5.95%, #017AE0 139.29%);
    padding: 10px 30px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 25px;
}

.afterlogin_avtore {
    width: 35px !important;
    height: 35px !important;
    background-color: var(--primary) !important;

}

.adult_dropdown_home {
    /* width: 350px; */
    left: -45px !important;
    right: 0px !important;
    top: 10px !important;
}

.adult_dropdown {
    margin-left: 0px;
    width: 84%;
}

.Product_detils_icons img {
    width: 20px;
}

.details_flex {
    display: flex;
    align-items: center;
}

.Product_detils_icons {
    width: 30px;
}

.guest-input1 {
    border-top: 1px solid #00000050;
    width: 100%;
    padding: 5px 0px 5px 15px;
    display: block;
}

.guest-input1:nth-child(1) {
    border: none;
}

.details_click_open>button {
    width: 100%;
}

.gest_search1 {
    width: 100%;
}

.rs-picker-toggle-wrapper {
    width: 100%;
}

.adult_input_box {
    padding: 5px 0px !important;
}

.details_page_datepicker>.rs-picker-popup-daterange {
    background-color: red;
}

.ddsds {
    width: 100%;
}

.notifaction-dot {
    height: 8px;
    width: 8px;
    background: #198754;
    border-radius: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    left: -4px;
    top: 27px;
}

.adult_dropdown>button {
    width: 100%;
}

.booking_confrim_table_secound {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb73
}

.booking_confrim_table_secound_inner_wrapper {
    width: 25%;
    padding: 5px 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.booking_confrimbooking_title {
    color: rgba(96, 96, 96, 1);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.booking_confrimbooking_subtitle_title {
    font-size: 12px;
    font-weight: 600;
    color: rgb(61 61 61);
    text-transform: capitalize;
}

.doucement_wrapper {
    padding: 10px 0px;
}

.input_field_document_wrapper {
    display: flex;
    padding: 5px 0px 15px;
    align-items: center;
}

.document_data {
    font-size: 14px;
    font-weight: 500;
    color: rgba(61, 66, 90, 1);
    padding-left: 6px;
}

.radio_box_document {
    height: 15px;
    width: 15px;
}

.doucement_valuse_box {
    width: 50%;
    display: flex;
    align-items: center;
}

.uplodad_document_file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.uplodad_document_file+label {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: rgba(110, 127, 214, 1);
    display: inline-block;
    border-radius: 4px;
    padding: 10px 25px;
    margin-top: 10px;
}

.uplodad_document_file:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.uplodad_document_file+label * {
    pointer-events: none;
}

.rental_login_fm_adhaarnumber {
    border-radius: 5px;
    display: block;
    margin-right: 33px;
    width: 100%;
    padding: 9px 10.75px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #3D425A;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.uploade_file_notifaction {
    color: rgba(177, 177, 177, 1);
    font-size: 12px;
    font-weight: 400;
}

.react-international-phone-input {
    height: 41px !important;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    border-right: none;
    height: 41px;
}

.mobile_slider_property {
    display: none;
}

.verdorbtn {
    background: rgba(110, 127, 214, 1) !important;
    color: #ffff !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
    padding: 7px 20px !important;
    margin-right: 25px;
    letter-spacing: 0.3px;
}

.vendorbtn {
    background-color: #6e7fd6;
}

/***************responsive*********/
@media only screen and (max-width :480px) {
    .date_box_rights {
        width: 13%;
        text-align: end;
        border: 1px solid #f1f1f1;
        border-radius: 50px;
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px;
        margin-top: -30px;
        height: 40px;
        width: 40px;
    }
    .input_box {
        margin-left: 10px;
        width: 90%;
        font-size: 12px;
    }
    .direction_title_wrapper {
        padding-top: 5px;
    }
    .trip_details_flex_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(217, 217, 217, 1);
        flex-direction: column-reverse;
    }
    .trips_details_pitcure img {
        height: 225px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 15px;
    }
    .trips_details_booking_clumn {
        width: 100%;
    }
    .mobile_slider_property {
        display: block;
    }

    .mobile_multi_sliderimages img {
        border-radius: 5px;
        height: 200px;
        width: 100%;
    }

    .details_picture_banner {
        display: none;
    }

    .search_destination>.css-1nmdiq5-menu {
        top: 100%;
        position: absolute;
        width: 100% !important;
        left: 0px;
        z-index: 1;
        font-size:12px;
        background-color: hsl(0, 0%, 100%);
        border-radius: 12px !important;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
        margin-bottom: 8px;
        margin-top: 12px !important;
        box-sizing: border-box;
    }

    .rangeslider_filter>.MuiSlider-thumb>.MuiSlider-valueLabelOpen {
        background-color: #6e7fd6;
        background-color: var(--primary) !important;
    }

    .confrim-deletion>.modal-dialog>.modal-content {
        border-radius: 13px;
        background-color: #fff;
        border: 1px solid #c9c6c6;
        width: 100%;
    }

    .adoneservices_main_wrapper {
        display: flex;
        align-items: center;
        margin: 0px 0px;
        padding: 11px 0px;
        /* border-bottom: 1px solid #CBCBCB66; */
        flex-direction: column;
    }

    .adoneservices_text {
        width: 100%;
    }

    .adoneservices_button {
        width: 100%;
        margin-top: 10px;
    }

    .button_adone_main {
        float: left;
        border: solid #006aa0ab;
        border-width: 1.3px;
        border-radius: 4px;
        overflow: auto;
        width: 115px;
    }

    .social_icon {
        margin: 0px 5px;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        background-color: var(--primary);
        text-align: center;
        line-height: 32px;
    }

    .guest-input1 {
        border-top: 1px solid #00000050;
        width: 100%;
        padding: 5px 0px 0px 0px !important;
        display: block;
        margin: 0px !important;
    }

    .adoneservices_main_wrapper {
        display: flex;
        align-items: center;
        margin: 0px 0px;
        padding: 11px 0px;
        /* border-bottom: 1px solid #CBCBCB66; */
        flex-direction: column;
    }

    .adoneservices_text {
        width: 100%;
    }

    .adoneservices_button {
        width: 100%;
        margin-top: 10px;
    }

    .button_adone_main {
        float: left;
        border: solid #006aa0ab;
        border-width: 1.3px;
        border-radius: 4px;
        overflow: auto;
        width: 115px;
    }

    .social_icon {
        margin: 0px 5px;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        background-color: var(--primary);
        text-align: center;
        line-height: 32px;
    }

    .guest-input1 {
        border-top: 1px solid #00000050;
        width: 100%;
        padding: 5px 0px 0px 0px !important;
        display: block;
        margin: 0px !important;
    }

    .rs-picker-toggle-wrapper {
        /* margin: -8px 1px 0px -15px !important; */
    }

    .fotter_copyright_icone {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .conpyright_text {
        margin-top: 0px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
    }

    .trippage_inner_wrapper {
        display: grid;
        justify-content: unset;
        align-items: flex-start;
    }

    .trips_picture_new {
        margin-right: 0px;
    }

    .trip_list_btn ul li {
        list-style-type: none;
        display: inline;
        font-size: 12px;
        font-weight: 600;
        margin: 0px 15px 0px 0px;
        padding: 8px;
        color: #191919CC;
        cursor: pointer;
    }

    .trips_picture_new img {
        border-radius: 5px;
        height: 175px;
        width: 100%;
        margin: 0px 0px;
    }

    .tripe_left_bar {
        display: grid;
    }

    .trips_contant_data {
        padding-top: 10px;
    }

    .booking_id {
        padding-top: 10px;
    }

    .booked_status_inner_wrapper {
        display: grid;
        justify-content: start;
    }

    .boked_status {
        padding-top: 5px;
    }

    .value_profile_date {
        font-size: 12px;
        color: #606060;
        font-weight: 400;
        padding: 3px 0px;
    }

    .rating_title {
        font-size: 8px;
        font-weight: 500;
        color: #606060;
    }

    .rating_box {
        width: 50px;
        text-align: center;
    }

    .rating_star_inner_wrapper {
        justify-content: start;
        display: grid;
        align-items: center;
    }

    .addfeedback_title {
        font-size: 12px;
        font-weight: 500;
        color: var(--textcolor) !important;
        padding: 20px 10px 0px;
    }






    .trip_left_bar {
        width: 100%;
    }

    .css-15befwp-MuiRating-root {
        font-size: 18px;
        color: #faaf00;
    }

    .trips_picture img {
        border-radius: 5px;
        height: 150px;
        width: 100%;
        margin: 0px 0px;
    }

    .trip_center_bar {
        width: 100%;
        padding: 10px 0px;
    }

    .tripwrapper_row {
        display: grid;
        align-items: center;
        padding: 12px 7px;
    }

    .main-photo {
        width: 100%;
    }

    .photos-grid-container {
        height: 100%;
        display: flex !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 10px;
        align-items: start;
        flex-wrap: wrap;
    }

    .images_box {
        height: 230px;
    }

    .adult_dropdown_home {
        width: 100% !important;
        left: 0px !important;
        top: 5px !important;
    }

    .gest_search {
        padding: 0px 0px;
    }

    .search_icone_herobox1 img {
        width: 17px;
    }

    .search_icone_herobox1 {
        width: 10%;
        text-align: end;
    }

    .adult_dropdown {
        margin-left: 0px;
        width: 100%;
    }

    .adult_dropdown>button {
        width: 100%;
    }







    .rs-picker-popup .rs-picker-toolbar-ranges {
        max-width: 400px;
        display: flex !important;
    }

    .rs-picker-popup .rs-calendar-table {
        width: 100% !important;
    }

    .rs-stack {
        display: -ms-flexbox;
        display: block !important;
    }

    .rs-picker-popup.rs-picker-popup-daterange {
        padding: 0px;
        left: 0px !important;
        right: 0px !important;
        width: 88%;
        margin: 10px auto;
    }

    .background_overlay {
        background: #0000005c;
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    /*******property details*****/
    .property_details_form_wrapper {
        width: 100%;
        margin: 0px auto;
        background: #fff;
        padding: 25px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    /*******End property details*****/
    /**********booking confrim********/
    .booking_confrim_details h6 {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .booking_confrim_details_title h6 {
        font-size: 13px;
    }

    .disrection_title h6 {
        font-weight: 600;
        font-size: 13px;
    }

    .bookign_id {
        font-size: 17px;
    }

    .booking_date {
        font-size: 13px;
    }

    .booking_product_title h6 {
        font-size: 14px;
    }

    .mobile_booking {
        display: block !important;
    }

    .booking_confrim_picture img {
        height: 200px;
        width: 100%;
        border-radius: 5px;
    }

    .booking_text_confrim_idwrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .desktop_booking {
        display: none !important;
    }

    .yourtrip_wrapper_box_booking_confrim {
        width: 100%;
        position: relative;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
    }

    .booking_confrim_title h3 {
        font-size: 14px;
        font-weight: 600;
        color: var(--textcolor);
        margin-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .successfully_icon {
        width: 25px;
        margin-top: 0px;
    }

    .booking_text_confrim_idwrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
    }

    .booking_product_wrapper_confrim {
        display: flex;
        flex-wrap: wrap;
        text-align: start;
        padding-top: 20px;
    }

    .direction_wrapper {
        padding-top: 5px;
    }

    .detail_wrapper_details {
        border-top: 1px solid rgb(217 217 217 / 40%);
        padding: 10px 0px 0px;
    }

    .booking_confrim_table_secound {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb73;
        flex-wrap: wrap;
    }

    .booking_confrim_table_secound_inner_wrapper {
        width: 50%;
        padding: 5px 1px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    /*********End*booking confrim********/
    /*******profile page****/
    .profile_save_btn {
        margin-left: 4px;
        margin-top: 2px;
        padding: 3px 20px !important;
    }

    .profile_edit_form_input {
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
    }

    .avatar-upload_profile_rental {
        position: relative;
        max-width: 130px;
        margin: 0px auto 0px;
    }

    .avatar-upload_profile_rental .avatar-preview {
        width: 110px;
        height: 110px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .profile_wrapper {
        width: 100%;
        margin: 0px auto;
    }

    /*********end profile page****/
    .price_range_title {
        padding-bottom: 20px;
    }

    .css-188mx6n-MuiSlider-root {
        width: 90% !important;
        margin-left: 10px;
        padding: 13px 0;
    }

    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check)+.btn:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: #fff !important;
    }

    .filter_dropdown>.form-select {
        font-size: 12px;
    }

    .shortbylable {
        font-size: 12px;
        display: none;
    }

    .filter_inner_wrapper {
        padding-top: 5px;
    }

    .filter_dropdown {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }

    .rental_filter_btn {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #212529 !important;
        padding: 0px 0px 15px !important;
    }

    .mobile_filter {
        display: block;
    }

    .panel-default {
        padding: 7px 0px;
        border-bottom: 1px solid #dedbdba6;
    }

    .filter_dropdown_width {
        width: 100%;
        border-radius: 0px !important;
        background: #fff !important;
        border: 0px !important;
        padding: 10px 15px !important;
    }

    .filter_left_sidebar {
        margin-top: 0px;
        padding: 0px;
    }

    .desktop_filter {
        display: none;
    }

    .filter_number {
        font-size: 13px;
        color: #595959;
        font-weight: 500;
        width: 50%;
        /* margin-top: 20px; */
    }

    .rs-picker-daterange-panel {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .checkincheckout_date>.rs-picker-daterange-calendar-group {
        height: 274px;
        min-width: 100% !important;
        display: flex;
        flex-wrap: wrap;
    }

    .rs-picker-daterange-calendar-group {
        height: auto !important;
        min-width: 100% !important;
    }


    .desktop_cenclelation {
        display: none;
    }

    .mobile_cenclelation {
        display: block;
    }

    .payment_confrim_btn {
        border: 0px;
        padding: 8px 40px;
        border-radius: 5px;
        background-color: var(--primary);
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Inter';
        width: 100%;
    }

    .desktop_checkout_btn {
        display: none;
    }

    .mobile_btn_checkout {
        display: block !important;
    }

    .card_box_riben {
        width: 15%;
    }

    /********check out page*****/
    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    .yourtrip_wrapper_box {
        width: 100%;
        margin-bottom: 15px;
    }

    .yourtrip_wrapper_box1 {
        width: 100%;
    }

    .gust-text {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }

    .checkoutpage_title_box {
        font-size: 15px;
    }

    .cancelpolcey {
        font-size: 12px;
        font-weight: 500;
        color: #606060;
    }

    .checkoutporductname p {
        font-size: 13px;
        font-weight: 600;
    }

    /**********end check out page**********/





    /***********DETAILS PAGES******/
    .price {
        font-size: 20px;
        font-weight: 700;
        color: #006aa0;
        padding: 0px 0px;
    }

    .htro_price {
        font-size: 14px;
    }

    .discount_price {
        font-size: 11px;
        font-weight: 500;
    }

    .details_price_title_resverbox {
        font-size: 13px;
        font-weight: 500;
    }

    .details_page_inner_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        border-bottom: 1px solid #e4e3e35c;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 15px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 85px;
        width: 85px;
        border-radius: 5px;
    }

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 12px;
    }

    .task-progress p {
        font-size: 12px;
    }

    .order_alert {
        font-size: 14px;
        font-weight: 500;
        color: red;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 40px!important;
        height: 40px!important;
    }

    .card_box_riben_dtais_page {
        padding: 3px 5px;
        width: 55px;
        height: 25px;
    }

    .r1lutz1s {
        display: none;
    }

    .rating_center_box {
        padding: 0px 0px 15px;
    }

    .order-container {
        border-radius: 13px;
        padding: 0px 0px;
        /* box-shadow: 0 0 5px 5px #00000015; */
    }

    .order-data {
        border: 1px solid rgba(96, 96, 96, 1);
        border-radius: 13px;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .Product_detils_icons img {
        width: 18px;
    }

    .places {
        display: none;
    }

    .property_detaols_main_wrapper {
        padding: 25px 0px;
    }

    .details_picture_tittle h4 {
        font-size: 18px;
    }

    .details_picture_banner img {
        height: 200px;
    }

    .details_pages_dis p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }

    .details_picture_sub_tittle h5 {
        font-size: 16px;
    }

    .features_lists {
        width: 100%;
        padding: 10px 10px;
        border: 1px solid #f1f1f1;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .features_lists_wrapper {
        display: flex;
        /* flex-wrap: wrap; */
        display: grid;
        grid-template-columns: 50% 47%;
        grid-gap: 10px;
    }

    .nametext {
        font-size: 12px;
    }


    /*****PROFILE PAGE*******/
    .profile_section_wrapper {
        width: 100%;
    }

    .title_profile {
        font-size: 13px;
    }

    .value_profile {
        font-size: 12px;
    }

    .profileeditepencile i {
        font-size: 14px;
    }

    /*****END PROFILE PAGE*******/

    .guests.gest_search {
        width: 100%;
        background: #fff;
    }

    .gest_search {
        margin-bottom: 7px;
        height: 55px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .guest-input1 {
        box-shadow: none !important;
    }

    .details_pagecheck {
        box-shadow: none !important;
    }

    .check-in.gest_search {
        width: 100%;
        background: #fff;
    }

    .location.gest_search {
        width: 100%;
        background: #fff;
    }

    .rental_project_category ul {
        margin: 0px;
        padding: 0px;
        display: -webkit-box;
        justify-content: center;
        overflow-x: scroll;
        width: 100%;
    }

    .rental_project_category ul li {
        display: -webkit-box;
        justify-content: center;
        width: auto;
        padding: 10px;
    }

    .bar {
        width: 100%;
        background: #f1f1f1;
        height: auto !important;
        border-radius: 10px;
        display: block;
        justify-content: center;
        font-size: 0.6rem;
        box-shadow: none;
        padding: 10px;
        flex-wrap: wrap;
    }

    .serach_input {
        font-size: 12px;
        color: #575757;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .guests_search_btn button {
        border-radius: 4px;
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        width: 100%;
        line-height: 43px;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .guests_search_btn {
        width: 100%;
        border-radius: 4px;
    }

    .appinfo_title h2 {
        font-size: 21px;
        font-weight: 700;
        padding-top: 25px;
        color: #006AA0;
        text-align: center;
        line-height: 26px;
    }

    .appinfo_title_para h5 {
        font-size: 19px;
        color: #383737;
        padding-top: 10px;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
    }

    .apps_downlode_inner_wrapper {
        display: flex;
        align-items: center;
        margin-top: 30px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .downlode_apps_text {
        padding-bottom: 5px;
        margin-top: 30px;
    }

    .donwlode_playsore img {
        width: 50%;
    }

    .images_box>.nav-link>img {
        border-radius: 10px 10px 0px 0px;
        width: 100% !important;
        height: 235px !important;
    }

    .card_wrapper_box {
        height: 335px;
    }

    .viewdecoration_inner_wrapper {
        display: flex;
        height: auto;
        flex-direction: column-reverse;
    }

    .decoration_right {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        height: 250px;
    }

    .decoration_left {
        background: #FEF5EC;
        padding: 25px 20px;
        border-radius: 0px 0px 10px 10px;
        border: none;
        text-align: center;
        width: 100%;
    }

    .appsection_inner_wrapper {
        padding: 35px 10px;
    }

    /******Footer start*****/
    .single_footer {
        padding: 10px 0px;
    }

    .footer_downlode_img {
        display: flex;
    }

    .filter_text_dummy {
        display: none;
    }
}

@media (min-width: 481px) and (max-width:575px) {
    .mobile_multi_sliderimages img {
        border-radius: 5px;
        height: 250px;
        width: 100%;
    }
    .input_box {
        margin-left: 10px;
        width: 90%;
        font-size: 13px;
    }
    .mobile_slider_property {
        display: block;
    }

    .mobile_multi_sliderimages img {
        border-radius: 5px;
    }

    .details_picture_banner {
        display: none;
    }

    .filter_text_dummy {
        display: none;
    }

    .booking_confrim_table_secound {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb73;
        flex-wrap: wrap;
    }

    .booking_confrim_table_secound_inner_wrapper {
        width: 50%;
        padding: 5px 1px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .guest-input1 {
        border-top: 1px solid #00000050;
        width: 100%;
        padding: 5px 0px 0px 0px !important;
        display: block;
        margin: 0px !important;
    }

    .adult_dropdown_home {
        width: 100% !important;
        left: 0px !important;
        top: 5px !important;
    }

    .gest_search {
        padding: 0px 0px;
    }

    .search_icone_herobox1 img {
        width: 17px;
    }

    .search_icone_herobox1 {
        width: 10%;
        text-align: end;
    }

    .adult_dropdown {
        margin-left: 0px;
        width: 100%;
    }

    .adult_dropdown>button {
        width: 100%;
    }

    .addfeedback_title {
        font-size: 11px !important;
        font-weight: 600;
        color: var(--textcolor) !important;
    }

    .trip_left_bar {
        width: 35%;
    }

    .css-15befwp-MuiRating-root {
        font-size: 18px;
        color: #faaf00;
    }

    .trips_picture img {
        border-radius: 5px;
        height: 70px;
        width: 70px;
        margin: 0px 0px;
    }

    .trip_center_bar {
        width: 100%;
        padding: 10px 0px;
    }

    .title_profile {
        font-size: 13px;
        font-weight: 500;
    }

    .value_profile {
        font-size: 12px;
    }

    .ruppee_text {
        font-size: 14px;
        font-weight: 600;
        color: var(--textcolor) !important;
    }

    .css-15befwp-MuiRating-root {
        font-size: 19px !important;
    }

    .guest-input1 {
        box-shadow: none !important;
    }

    .details_pagecheck {
        box-shadow: none !important;
    }

    .rs-picker-popup .rs-picker-toolbar-ranges {
        max-width: 400px;
        display: flex !important;
    }

    .rs-picker-popup .rs-calendar-table {
        width: 100% !important;
    }

    .rs-stack {
        display: -ms-flexbox;
        display: block !important;
    }

    .rs-picker-daterange-calendar-group {
        height: 274px;
        min-width: 100% !important;
    }

    .rs-stack {
        display: -ms-flexbox;
        display: block !important;
    }

    .rs-picker-daterange-panel {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .rs-picker-popup.rs-picker-popup-daterange {
        padding: 0;
        margin-left: -60px;
        margin-right: 31px;
    }

    .images_box {
        height: 315px;
    }

    .background_overlay {
        background: #0000005c;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*******property details*****/
    .property_details_form_wrapper {
        width: 100%;
        margin: 0px auto;
        background: #fff;
        padding: 25px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    /*******End property details*****/
    /**********booking confrim********/
    .booking_confrim_details h6 {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .booking_confrim_details_title h6 {
        font-size: 13px;
    }

    .disrection_title h6 {
        font-weight: 600;
        font-size: 13px;
    }

    .bookign_id {
        font-size: 17px;
    }

    .booking_date {
        font-size: 13px;
    }

    .booking_product_title h6 {
        font-size: 14px;
    }

    .mobile_booking {
        display: block !important;
        ;
    }

    .booking_confrim_picture img {
        height: 280px;
        width: 100%;
    }

    .booking_text_confrim_idwrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0px;
    }

    .desktop_booking {
        display: none !important;
    }

    .yourtrip_wrapper_box_booking_confrim {
        width: 100%;
        position: relative;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
    }

    .booking_confrim_title h3 {
        font-size: 16px;
    }

    .successfully_icon {
        width: 25px;
        margin-top: -5px;
    }

    .booking_text_confrim_idwrapper {
        display: grid;
        flex-wrap: wrap;
        padding-top: 10px;
    }

    .booking_product_wrapper_confrim {
        display: flex;
        flex-wrap: wrap;
        text-align: start;
        padding-top: 20px;
    }

    .direction_wrapper {
        padding-top: 5px;
    }

    .detail_wrapper_details {
        border-top: 1px solid rgb(217 217 217 / 40%);
        padding: 10px 0px 0px;
    }

    /*********End*booking confrim********/
    /*******profile page****/
    .profile_save_btn {
        margin-left: 7px;
        margin-top: 10px;
    }

    .profile_edit_form_input {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .avatar-upload_profile_rental {
        position: relative;
        max-width: 130px;
        margin: 0px auto 0px;
    }

    .avatar-upload_profile_rental .avatar-preview {
        width: 110px;
        height: 110px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .profile_wrapper {
        width: 100%;
        margin: 0px auto;
    }

    /*********end profile page****/
    .price_range_title {
        padding-bottom: 20px;
    }

    .rental_filter_btn.btn:first-child:active,
    :not(.btn-check)+.btn:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: #fff;
    }

    .filter_dropdown>.form-select {
        font-size: 13px;
    }

    .shortbylable {
        font-size: 13px;
    }

    .filter_inner_wrapper {
        padding-top: 5px;
    }

    .filter_dropdown {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }

    .rental_filter_btn {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #212529 !important;
        padding: 0px !important;
    }

    .mobile_filter {
        display: block;
    }

    .filter_dropdown_width {
        width: 100%;
        border-radius: 0px !important;
        background: #fff !important;
        border: 0px !important;
        padding: 10px 15px !important;
    }

    .filter_left_sidebar {
        margin-top: 20px;
        padding: 0px;
    }

    .desktop_filter {
        display: none;
    }

    .filter_number {
        font-size: 15px;
        color: #595959;
        font-weight: 500;
        width: 50%;
        margin-top: 20px;
    }



    .desktop_cenclelation {
        display: none;
    }

    .mobile_cenclelation {
        display: block;
    }

    .payment_confrim_btn {
        border: 0px;
        padding: 8px 40px;
        border-radius: 5px;
        background-color: var(--primary);
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Inter';
        width: 100%;
    }

    .desktop_checkout_btn {
        display: none;
    }

    .mobile_btn_checkout {
        display: block !important;
    }

    .card_box_riben {
        width: 12%;
    }

    /********check out page*****/
    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    .yourtrip_wrapper_box {
        width: 100%;
        margin-bottom: 15px;
    }

    .yourtrip_wrapper_box1 {
        width: 100%;
    }

    .gust-text {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }

    .checkoutpage_title_box {
        font-size: 15px;
    }

    .cancelpolcey {
        font-size: 12px;
        font-weight: 500;
        color: #606060;
    }

    .checkoutporductname p {
        font-size: 13px;
        font-weight: 600;
    }

    /**********end check out page**********/

    /*********PRODUCT DETAILS ********/
    .price {
        font-size: 20px;
        font-weight: 700;
        color: #006aa0;
        padding: 0px 0px;
    }

    .htro_price {
        font-size: 14px;
    }

    .discount_price {
        font-size: 11px;
        font-weight: 500;
    }

    .details_price_title_resverbox {
        font-size: 13px;
        font-weight: 500;
    }

    .details_page_inner_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        border-bottom: 1px solid #e4e3e35c;
    }

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 12px;
    }

    .task-progress p {
        font-size: 12px;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 15px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 85px;
        width: 85px;
        border-radius: 5px;
    }

    .order_alert {
        font-size: 14px;
        font-weight: 500;
        color: red;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 45px !important;
        height: 45px !important;
    }

    .card_box_riben_dtais_page {
        padding: 3px 5px;
        width: 55px;
        height: 25px;
    }

    .r1lutz1s {
        display: none;
    }

    .rating_center_box {
        padding: 0px 0px 15px;
    }

    .order-container {
        border-radius: 13px;
        padding: 0px 0px;
        /* box-shadow: 0 0 5px 5px #00000015; */
    }

    .order-data {
        border: 1px solid rgba(96, 96, 96, 1);
        border-radius: 13px;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .Product_detils_icons img {
        width: 18px;
    }

    .places {
        display: none;
    }

    .property_detaols_main_wrapper {
        padding: 25px 20px;
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: #fff !important;
    }
    .details_picture_tittle h4 {
        font-size: 18px;
    }

    .details_picture_banner img {
        height: 200px;
    }

    .details_pages_dis p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }

    .details_picture_sub_tittle h5 {
        font-size: 16px;
    }

    .features_lists {
        width: 100%;
        padding: 10px 10px;
        border: 1px solid #f1f1f1;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .features_lists_wrapper {
        display: flex;
        /* flex-wrap: wrap; */
        display: grid;
        grid-template-columns: 50% 47%;
        grid-gap: 10px;
    }

    .nametext {
        font-size: 16px;
    }

    /*********END PRODUCT DETAILS********/
    .viewdecoration_inner_wrapper {
        display: flex;
        height: auto;
        flex-direction: column-reverse;
    }

    .decoration_right {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        height: 250px;
    }

    .decoration_left {
        background: #FEF5EC;
        padding: 30px 15px;
        border-radius: 0px 0px 10px 10px;
        border: none;
        text-align: center;
        width: 100%;
    }

    .guests.gest_search {
        width: 100%;
        background: #fff;
    }

    .gest_search {
        margin-bottom: 7px;
        height: 55px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .check-in.gest_search {
        width: 100%;
        background: #fff;
    }

    .location.gest_search {
        width: 100%;
        background: #fff;
    }

    .rental_project_category ul {
        margin: 0px;
        padding: 0px;
        display: -webkit-box;
        justify-content: center;
        overflow-x: scroll;
        width: 100%;
    }

    .rental_project_category ul li {
        display: -webkit-box;
        justify-content: center;
        width: auto;
        padding: 10px;
    }

    .bar {
        width: 100%;
        background: #f1f1f1;
        height: auto !important;
        border-radius: 10px;
        display: block;
        justify-content: center;
        font-size: 0.6rem;
        box-shadow: none;
        padding: 19px;
        flex-wrap: wrap;
        margin: 0px auto;

    }

    .guests_search_btn button {
        border-radius: 4px;
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        width: 100%;
        line-height: 43px;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .guests_search_btn {
        width: 100%;
        border-radius: 4px;
    }

    .appinfo_title h2 {
        font-size: 20px;
        font-weight: 700;
        padding-top: 30px;
        color: #006AA0;
        text-align: center;
        line-height: 24px;
    }

    .appinfo_title_para h5 {
        font-size: 19px;
        color: #383737;
        padding-top: 10px;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
    }

    .apps_downlode_inner_wrapper {
        display: flex;
        align-items: center;
        margin-top: 30px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .downlode_apps_text {
        padding-bottom: 5px;
        margin-top: 30px;
    }

    .donwlode_playsore img {
        width: 50%;
    }

    .images_box>.nav-link>img {
        border-radius: 10px 10px 0px 0px;
        width: 100% !important;
        height: 315px !important;
    }

    .card_wrapper_box {
        height: 425px;
    }

    /******Footer start*****/
    .single_footer {
        padding: 10px 0px;
    }

    .footer_downlode_img {
        display: flex;
    }

    .card_box_riben {
        width: 10%;
    }

    .filter_text_dummy {
        display: none !important;
    }
}



@media (min-width:576px) and (max-width: 767px) {
    .mobile_multi_sliderimages img {
        border-radius: 5px;
        height: 270px;
        width: 100%;
    }
    .date_box_rights {
        width: 13%;
        text-align: end;
        border: 1px solid #f1f1f1;
        border-radius: 50px;
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px;
        margin-top: -30px;
        height: 40px;
        width: 40px;
    }
    .mobile_slider_property {
        display: block;
    }

    .mobile_multi_sliderimages img {
        border-radius: 5px;
    }

    .details_picture_banner {
        display: none;
    }

    .filter_text_dummy {
        display: none;
    }

    .booking_confrim_table_secound {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb73;
        flex-wrap: wrap;
    }

    .booking_confrim_table_secound_inner_wrapper {
        width: 50%;
        padding: 5px 1px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .guest-input1 {
        border-top: 1px solid #00000050;
        width: 100%;
        padding: 5px 0px 0px 0px !important;
        display: block;
        margin: 0px !important;
    }

    .adult_dropdown_home {
        width: 100% !important;
        left: 0px !important;
        top: 5px !important;
    }

    .gest_search {
        padding: 0px 0px;
    }

    .search_icone_herobox1 img {
        width: 17px;
    }

    .search_icone_herobox1 {
        width: 10%;
        text-align: end;
    }

    .adult_dropdown {
        margin-left: 0px;
        width: 100%;
    }

    .adult_dropdown>button {
        width: 100%;
    }

    .trip_left_bar {
        width: 35%;
    }

    .css-15befwp-MuiRating-root {
        font-size: 18px;
        color: #faaf00;
    }

    .trips_picture img {
        border-radius: 5px;
        height: 70px;
        width: 70px;
        margin: 0px 0px;
    }

    .trip_center_bar {
        width: 100%;
        padding: 10px 0px;
    }

    .title_profile {
        font-size: 13px;
        font-weight: 500;
    }

    .value_profile {
        font-size: 12px;
    }

    .ruppee_text {
        font-size: 14px;
        font-weight: 600;
        color: var(--textcolor) !important;
    }

    .css-15befwp-MuiRating-root {
        font-size: 19px !important;
    }

    .guest-input1 {
        box-shadow: none !important;
    }

    .details_pagecheck {
        box-shadow: none !important;
    }

    .rs-picker-popup .rs-picker-toolbar-ranges {
        max-width: 400px;
        display: flex !important;
    }

    .rs-picker-popup .rs-calendar-table {
        width: 100% !important;
    }

    .rs-stack {
        display: -ms-flexbox;
        display: block !important;
    }

    .rs-picker-daterange-calendar-group {
        height: 274px;
        min-width: 100% !important;
    }

    .rs-stack {
        display: -ms-flexbox;
        display: block !important;
    }

    .rs-picker-daterange-panel {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .rs-picker-popup.rs-picker-popup-daterange {
        padding: 0;
        margin-left: -64px;
        margin-right: 101px;
    }



    .background_overlay {
        background: #0000005c;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*******property details*****/
    .property_details_form_wrapper {
        width: 100%;
        margin: 0px auto;
        background: #fff;
        padding: 25px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    /*******End property details*****/
    /******booking confrim**/
    .yourtrip_wrapper_box_booking_confrim {
        width: 100%;
        position: relative;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
    }

    /******End booking confrim**/
    /*******profile page****/
    .profile_save_btn {
        margin-left: 7px;
        margin-top: 0px;
    }

    .profile_edit_form_input {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .avatar-upload_profile_rental {
        position: relative;
        max-width: 130px;
        margin: 0px auto 0px;
    }

    .avatar-upload_profile_rental .avatar-preview {
        width: 110px;
        height: 110px;
        position: relative;
        border-radius: 100%;
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }

    .profile_wrapper {
        width: 100%;
        margin: 0px auto;
    }

    /*********end profile page****/
    /*******************filter page**************/
    .price_range_title {
        padding-bottom: 20px;
    }

    .css-188mx6n-MuiSlider-root {
        width: 90% !important;
        margin-left: 10px;
        padding: 13px 0;
    }

    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check)+.btn:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: #fff !important;
    }

    .filter_dropdown>.form-select {
        font-size: 13px;
    }

    .price_range_title {
        padding-bottom: 20px;
    }

    .css-188mx6n-MuiSlider-root {
        width: 90% !important;
        margin-left: 10px;
        padding: 13px 0;
    }

    .btn-check:checked+.btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check)+.btn:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: #fff !important;
    }

    .filter_dropdown>.form-select {
        font-size: 13px;
    }

    .filter_dropdown>.form-select {
        font-size: 13px;
    }

    .shortbylable {
        font-size: 13px;
    }

    .filter_inner_wrapper {
        padding-top: 5px;
    }

    .filter_dropdown {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }

    .rental_filter_btn {
        font-size: 15px !important;
        font-weight: 500 !important;
        color: #212529 !important;
    }

    .mobile_filter {
        display: block;
    }

    .filter_dropdown_width {
        width: 100%;
        border-radius: 0px !important;
        background: #fff !important;
        border: 0px !important;
        padding: 10px 15px !important;
    }

    .filter_left_sidebar {
        margin-top: 0px;
        padding: 0px;
    }

    .desktop_filter {
        display: none;
    }

    .filter_number {
        font-size: 15px;
        color: #595959;
        font-weight: 500;
        width: 50%;
        margin-top: 20px;
    }

    /**************filter page************/
    .desktop_cenclelation {
        display: none;
    }

    .mobile_cenclelation {
        display: block;
    }

    .payment_confrim_btn {
        border: 0px;
        padding: 8px 40px;
        border-radius: 5px;
        background-color: var(--primary);
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        font-family: 'Inter';
        width: 100%;
    }

    .desktop_checkout_btn {
        display: none;
    }

    .mobile_btn_checkout {
        display: block !important;
    }

    .card_box_riben {
        width: 12%;
    }

    /********check out page*****/
    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    .yourtrip_wrapper_box {
        width: 100%;
        margin-bottom: 15px;
    }

    .yourtrip_wrapper_box1 {
        width: 100%;
    }

    .gust-text {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }

    .checkoutpage_title_box {
        font-size: 15px;
    }

    .cancelpolcey {
        font-size: 12px;
        font-weight: 500;
        color: #606060;
    }

    .checkoutporductname p {
        font-size: 13px;
        font-weight: 600;
    }

    /**********end check out page**********/


    /*********PRODUCT DETAILS ********/
    .price {
        font-size: 20px;
        font-weight: 700;
        color: #006aa0;
        padding: 0px 0px;
    }

    .htro_price {
        font-size: 14px;
    }

    .discount_price {
        font-size: 11px;
        font-weight: 500;
    }

    .details_price_title_resverbox {
        font-size: 13px;
        font-weight: 500;
    }

    .details_page_inner_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-bottom: 1px solid #e4e3e35c;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 15px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 85px;
        width: 85px;
        border-radius: 5px;
    }

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 12px;
    }

    .task-progress p {
        font-size: 12px;
    }

    .order_alert {
        font-size: 14px;
        font-weight: 500;
        color: red;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 45px !important;
        height: 45px !important;
    }

    .card_box_riben_dtais_page {
        padding: 3px 5px;
        width: 55px;
        height: 25px;
    }

    .r1lutz1s {
        display: none;
    }

    .rating_center_box {
        padding: 0px 0px 15px;
    }

    .order-container {
        border-radius: 13px;
        padding: 0px 0px;
        /* box-shadow: 0 0 5px 5px #00000015; */
    }

    .order-data {
        border: 1px solid rgba(96, 96, 96, 1);
        border-radius: 13px;
        padding: 0px 10px;
        margin-top: 10px;
    }

    .Product_detils_icons img {
        width: 18px;
    }

    .places {
        display: none;
    }

    .property_detaols_main_wrapper {
        padding: 25px 0px;
    }

    .details_picture_tittle h4 {
        font-size: 18px;
    }

    .details_picture_banner img {
        height: 200px;
    }

    .details_pages_dis p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }

    .details_picture_sub_tittle h5 {
        font-size: 16px;
    }

    .features_lists {
        width: 100%;
        padding: 10px 10px;
        border: 1px solid #f1f1f1;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .features_lists_wrapper {
        display: flex;
        /* flex-wrap: wrap; */
        display: grid;
        grid-template-columns: 50% 47%;
        grid-gap: 10px;
    }

    .nametext {
        font-size: 14px;
    }

    /*********END PRODUCT DETAILS *******/



    .card_box_riben {
        width: 17%;
    }

    .viewdecoration_inner_wrapper {
        display: flex;
        height: auto;
        flex-direction: column-reverse;
    }

    .decoration_right {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        height: 250px;
    }

    .decoration_left {
        background: #FEF5EC;
        padding: 35px 15px;
        border-radius: 0px 0px 10px 10px;
        border: none;
        text-align: center;
        width: 100%;
    }

    .guests.gest_search {
        width: 100%;
        background: #fff;
    }

    .gest_search {
        margin-bottom: 7px;
        height: 55px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .check-in.gest_search {
        width: 100%;
        background: #fff;
    }

    .location.gest_search {
        width: 100%;
        background: #fff;
    }

    .rental_project_category ul {
        margin: 0px;
        padding: 0px;
        display: -webkit-box;
        justify-content: center;
        overflow-x: scroll;
        width: 100%;
    }

    .rental_project_category ul li {
        display: -webkit-box;
        justify-content: center;
        width: auto;
        padding: 10px;
    }

    .bar {
        width: 100%;
        background: #f1f1f1;
        height: auto !important;
        border-radius: 10px;
        display: block;
        justify-content: center;
        font-size: 0.6rem;
        box-shadow: none;
        padding: 19px;
        flex-wrap: wrap;
        margin: 0px auto;

    }

    .guests_search_btn button {
        border-radius: 4px;
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        width: 100%;
        line-height: 43px;
        border: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 1px;
    }

    .guests_search_btn {
        width: 100%;
        border-radius: 4px;
    }

    .appinfo_title h2 {
        font-size: 22px;
        font-weight: 700;
        padding-top: 15px;
        color: #006AA0;
        text-align: center;
        line-height: 30px;
    }

    .appinfo_title_para h5 {
        font-size: 19px;
        color: #383737;
        padding-top: 10px;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
    }

    .apps_downlode_inner_wrapper {
        display: flex;
        align-items: center;
        margin-top: 30px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .downlode_apps_text {
        padding-bottom: 5px;
        margin-top: 30px;
    }

    .donwlode_playsore img {
        width: 50%;
    }

    .images_box>.nav-link>img {
        border-radius: 10px 10px 0px 0px;
        width: 100% !important;
        height: 250px;
    }

    .card_wrapper_box {
        height: 300px;
    }

    /******Footer start*****/
    .single_footer {
        padding: 10px 0px;
    }

    .footer_downlode_img {
        display: flex;
    }
}









@media (min-width: 768px) and (max-width: 1024px) {
    .photo_grid-more button {
        background: #161616c9;
        border: 1px solid #918e8e;
        color: #fff;
        padding: 0px 8px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 3px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.45);
        /* border: 0px; */
        font-size: 11px;
    }

    .photo_grid-more {
        position: absolute;
        right: 0px;
        bottom: 15px;
    }

    .trip_left_bar {
        width: 35%;
    }

    .css-15befwp-MuiRating-root {
        font-size: 18px;
        color: #faaf00;
    }

    .trips_picture img {
        border-radius: 5px;
        height: 70px;
        width: 70px;
        margin: 0px 0px;
    }

    .trip_center_bar {
        width: 100%;
        padding: 10px 0px;
    }

    .title_profile {
        font-size: 13px;
        font-weight: 500;
    }

    .value_profile {
        font-size: 12px;
    }

    .ruppee_text {
        font-size: 14px;
        font-weight: 600;
        color: var(--textcolor) !important;
    }

    .css-15befwp-MuiRating-root {
        font-size: 19px !important;
    }

    .gest_search {
        padding: 10px 15px;
    }

    .input_box {
        margin-left: 10px;
        width: 95%;
    }

    .decoration_left {
        background: #FEF5EC;
        padding: 25px 20px;
        border-radius: 10px 0px 0px 10px;
        border: 1px solid #FEF5EC;
        width: 50%;
    }

    .decoration_right {
        width: 50%;
        border-radius: 0px 10px 10px 0px;
    }

    /******booking confrim**/
    .yourtrip_wrapper_box_booking_confrim {
        width: 100%;
        position: relative;
        padding: 10px;
        box-shadow: rgb(0 0 0 / 41%) 0px 1px 4px;
    }

    /******End booking confrim**/
    .filter_title_header>.panel-title>a {
        font-size: 12px;
        font-weight: 500;
        color: #191919;
        text-decoration: none;
    }

    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    .yourtrip_wrapper_box {
        box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
        padding: 15px 20px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        width: 100%;
        margin-bottom: 30px;
        background: #fff;
    }

    /*********PRODUCT DETAILS********/
    .price {
        font-size: 20px;
        font-weight: 700;
        color: #006aa0;
        padding: 0px 0px;
    }

    .htro_price {
        font-size: 14px;
    }

    .discount_price {
        font-size: 11px;
        font-weight: 500;
    }

    .details_price_title_resverbox {
        font-size: 13px;
        font-weight: 500;
    }

    .details_page_inner_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-bottom: 1px solid #e4e3e35c;
    }

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 13px;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 45px !important;
        ;
        height: 45px !important;
        ;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 14px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 90px;
        width: 90px;
        border-radius: 5px;
    }

    .task-progress p {
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .details_pagecheck {
        width: 100% !important;
    }

    .order-container {
        border-radius: 13px;
        padding: 10px 0px;
        /* box-shadow: 0 0 5px 5px #00000015; */
    }

    .img-short img {
        width: 186px;
        height: 145px;
        display: block;
        border-radius: 5px;
        object-fit: cover;
    }

    .img-short {
        width: 48%;
        height: 145px;
        /* float: left; */
        margin: 0px 0px 0px 0px;
    }

    .details_picture_banner img {
        height: 310px;
        border-radius: 5px;
        width: 100%;
    }

    /**********PRODUCT DETAILS END********/


    .css-1hb7zxy-IndicatorsContainer {
        display: none !important;
    }

    .guests_search_btn button {
        font-size: 11px;
        border: 0px;
    }

    .searchmain_title {
        font-size: 13px;
    }

    .serach_input {
        font-size: 12px;
        color: #575757;
        width: 144px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rs-input-group.rs-input-group-inside .rs-input {
        font-size: 12px !important;
    }

    .bar {
        width: 100%;
    }

    .rental_project_category ul {
        margin: 0px;
        padding: 0px;
        display: -webkit-box;
        justify-content: center;
        overflow-x: scroll;
        width: 100%;
    }

    .rental_project_category ul li {
        display: -webkit-box;
        border-right: 1px solid #ece8e8;
        justify-content: center;
        width: auto;
        padding: 10px;
    }

    .appinfo_title h2 {
        font-size: 18px;
        font-weight: 700;
        padding-top: 15px;
        color: #006AA0;
        text-align: center;
        line-height: 21px;
    }

    .appinfo_title_para h5 {
        font-size: 17px;
        color: #383737;
        padding-top: 3px;
        font-weight: 500;
        text-align: center;
        line-height: 25px;
    }

    .apps_downlode_inner_wrapper {
        display: flex;
        align-items: center;
        margin-top: 15px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
    }

    .downlode_apps_text {
        padding-bottom: 5px;
        margin-top: 10px;
    }

    .donwlode_playsore img {
        width: 50%;
    }

    .images_box>.nav-link>img {
        border-radius: 10px 10px 0px 0px;
        width: 100% !important;
        height: 200px;
    }

    .card_wrapper_box {
        height: 300px;
    }

    .card_box_riben {
        width: 25%;
        text-align: end;
        font-size: 12px;
    }

    .propery_left {
        width: 75%;
    }

    .text_in_one_line_property_name {
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-top: 3px;
    }

    .apps_images img {
        width: 95%;
    }

    .ruppee_text {
        font-size: 15px;
        font-weight: 600;
        color: #006AA0;
    }

    /**************FOOTER **********/
    .footer_hedding_title h4 {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
    }

    .footer_menu_list li a {
        font-size: 13px;
    }

    .conpyright_text {
        font-size: 11px;
    }

    .social_icon {
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 32px;
    }

    .rental_project_category ul li {
        display: -webkit-box;
        border-right: 1px solid #ece8e8;
        justify-content: center;
        width: auto;
        padding: 10px;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .card_box_riben {
        width: 25%;
        text-align: end;
        font-size: 12px;
    }

    .propery_left {
        width: 75%;
    }

    .filter_title_header>.panel-title>a {
        font-size: 12px;
        font-weight: 500;
        color: #191919;
        text-decoration: none;
    }

    .ruppee_text {
        font-size: 15px;
        font-weight: 600;
        color: #006AA0;
    }

    .yourtrip_wrapper_box {
        box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
        padding: 15px 20px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        width: 100%;
        margin-bottom: 30px;
        background: #fff;
    }

    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    /*********PRODUCT DETAILS********/

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 13px;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 50px !important;
        ;
        height: 50px !important;
        ;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 14px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 90px;
        width: 90px;
        border-radius: 5px;
    }

    .task-progress p {
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .details_pagecheck {
        width: 100% !important;
    }


    .img-short img {
        width: 100%;
        height: 145px;
        display: block;
        border-radius: 5px;
        object-fit: cover;
    }

    .img-short {
        width: 48%;
        height: 145px;
        /* float: left; */
        margin: 6px 0px 0px 0px;
        /* margin: 1px auto; */
    }

    .details_picture_banner img {
        height: 310px;
        border-radius: 5px;
        width: 100%;
    }

    /**********PRODUCT DETAILS END********/
    .rental_project_category li a {
        padding: 13px 9px;
        font-size: 13px;
    }

    .bar {
        width: 95%;
        margin: 0px auto;

    }

    .serach_input {
        font-size: 12px;
        color: #575757;
        width: 144px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}



@media (min-width: 768px) and (max-width: 991px) {
    .date_box_rights {
        width: 13%;
        text-align: end;
        border: 1px solid #f1f1f1;
        border-radius: 50px;
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px;
        margin-top: -30px;
        height: 40px;
        width: 40px;
    }
    .adoneservices_main_wrapper {
        display: flex;
        align-items: center;
        margin: 0px 0px;
        padding: 11px 0px;
        /* border-bottom: 1px solid #CBCBCB66; */
        flex-direction: column;
    }

    .adoneservices_text {
        width: 100%;
    }

    .adoneservices_button {
        width: 100%;
        margin-top: 10px;
    }

    .button_adone_main {
        float: left;
        border: solid #006aa0ab;
        border-width: 1.3px;
        border-radius: 4px;
        overflow: auto;
        width: 115px;
    }

    .guest-input1 {
        padding: 7px 7px;
    }

    .signle-product-space {
        margin-left: 0px !important;
        width: 100% !important;
        line-height: 16px;
    }

    .adult_dropdown_home {
        width: 285px !important;
        left: -32px !important;
        top: 10px !important;
    }

    .filter_left_sidebar {
        margin-top: 15px;
        padding: 0px 0px 0px 0px;
    }

    .filter_title_header>.panel-title>a {
        font-size: 12px;
        font-weight: 500;
        color: #191919;
        text-decoration: none;
    }

    /*******profile page****/
    .profile_wrapper {
        width: 100%;
        margin: 0px auto;
    }

    /*********end profile page****/
    /********check out page*****/
    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    .yourtrip_wrapper_box {
        width: 100%;
        margin-bottom: 15px;
    }

    .yourtrip_wrapper_box1 {
        width: 100%;
    }

    .gust-text {
        font-size: 12px;
        font-weight: 500;
        width: 100%;
    }

    .checkoutpage_title_box {
        font-size: 15px;
    }

    .cancelpolcey {
        font-size: 12px;
        font-weight: 500;
        color: #606060;
    }

    .checkoutporductname p {
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
    }

    .rental_checkoutimg img {
        height: 65px;
        width: 100px;
        border-radius: 5px;
    }

    .ruppee_text {
        font-size: 13px;
        font-weight: 600;
        color: #006AA0;
    }

    /**********end check out page**********/

    .price {
        font-size: 17px;
        font-weight: 700;
        color: #006aa0;
        padding: 0px 0px;
    }

    .htro_price {
        font-size: 12px;
    }

    .discount_price {
        font-size: 9px;
        font-weight: 500;
    }

    .details_price_title_resverbox {
        font-size: 12px;
        font-weight: 500;
    }

    .details_price_resverbox {
        font-weight: 500;
        font-size: 13px;
    }

    .details_page_inner_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;
        border-bottom: 1px solid #e4e3e35c;

    }

    .photo_grid-more button {
        background: #161616c9;
        border: 1px solid #918e8e;
        color: #fff;
        padding: 0px 8px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 3px;
        box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.45);
        /* border: 0px; */
        font-size: 11px;
    }

    .photo_grid-more {
        position: absolute;
        right: 0px;
        bottom: 15px;
    }

    .img-short img {
        width: 186px;
        height: 135px;
        display: block;
        border-radius: 5px;
        object-fit: cover;
    }

    .img-short {
        width: 48%;
        height: 135px;
        /* float: left; */
        margin: 0px 0px 0px 0px;
    }

    .details_picture_banner img {
        height: 280px;
        border-radius: 5px;
        width: 100%;
    }

    .r1lutz1s {
        font-size: 10px;
        font-weight: 600;
        color: #606060;
        display: none;
    }

    .task-progress p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #606060;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 12px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 90px;
        width: 90px;
        border-radius: 5px;
    }

    .reveiw_number {
        font-size: 10px;
        font-weight: 500;
    }

    .rating_details_wrapper {
        border: 1px solid #E1E1E1;
        padding: 15px;
        border-radius: 5px;
    }

    .nametext {
        font-size: 13px;
        font-weight: 400;
        color: #606060;
    }

    .i4wvyiy img {
        width: 21px;
    }

    .review_user_name h4 {
        font-size: 14px;
        font-weight: 600;
        color: #015B77;
        text-transform: capitalize;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 40px !important;
        height: 40px !important;
    }

    .review_log_dis h5 {
        font-size: 13px;
    }

    .details_pagecheck {
        width: 100% !important;
        ;
    }

    .order_alert {
        font-size: 12px;
        font-weight: 500;
        color: red;
        background: #dfdfdf;
        border-radius: 10px;
        padding: 10px;
        line-height: 15px;
    }
}




@media (min-width: 1281px) and (max-width:1400px) {
    .filter_title_header>.panel-title>a {
        font-size: 12px;
        font-weight: 500;
        color: #191919;
        text-decoration: none;
    }

    .ruppee_text {
        font-size: 15px;
        font-weight: 600;
        color: #006AA0;
    }

    .yourtrip_wrapper_box {
        box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
        padding: 15px 20px;
        border-radius: 8px;
        border: 1px solid #D9D9D9;
        width: 100%;
        margin-bottom: 30px;
        background: #fff;
    }

    .rentel_main_wrapperbox_checkout {
        width: 100%;
        margin: 0px auto;
    }

    /*********PRODUCT DETAILS********/

    .review_user_name h4 {
        font-size: 14px;
        text-transform: capitalize;
    }

    .review_log_dis h5 {
        font-size: 13px;
    }

    .css-1qim71y-MuiAvatar-root {
        width: 50px !important;
        ;
        height: 50px !important;
        ;
    }

    .card_box_riben_dtais_page_rightbox {
        background-color: var(--primary);
        font-size: 14px;
        color: #ffffff;
        padding: 10px;
        font-weight: 500;
        text-align: center;
        height: 90px;
        width: 90px;
        border-radius: 5px;
    }

    .task-progress p {
        font-size: 11px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .details_pagecheck {
        width: 100% !important;
    }


    .img-short img {
        width: 100%;
        height: 145px;
        display: block;
        border-radius: 5px;
        object-fit: cover;
    }

    .img-short {
        width: 48%;
        height: 145px;
        /* float: left; */
        margin: 6px 0px 0px 0px;
        /* margin: 1px auto; */
    }

    .details_picture_banner img {
        height: 310px;
        border-radius: 5px;
        width: 100%;
    }

    /**********PRODUCT DETAILS END********/
    .rental_project_category li a {
        padding: 13px 9px;
        font-size: 13px;
    }

    .bar {
        width: 95%;
        margin: 0px auto;

    }

    .serach_input {
        font-size: 12px;
        color: #575757;
        width: 144px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

div#react-select-2-listbox>div {
    padding: 8px 10px;
    border-bottom: 1px solid #f1f1f170;
}

div#react-select-2-listbox>div:hover {
    background-color: #EDEDED;
    cursor: pointer;
    border-radius: 8px;
}

.confrim-deletion:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;

}

.confrim-deletion>.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.product_deatils_list li {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
}